p {
  position: relative;
  margin-block-start: 0;
  color: $color-text;
}

ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

h1 {
  position: relative;
  margin-block-start: 0;
}

.section {
  position: relative;

  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: auto;

  height: auto;
  padding-block: 90px;

  &__title {
    $colors: (
      'yellow': $color-yellow,
      'orange': $color-orange,
      'red': $color-red,
    );

    position: relative;

    grid-area: 1 / 1 / 2 / 2;

    height: fit-content;

    font-size: 22px;
    font-weight: 400;
    color: rgba(black, 0.5);

    span {
      position: relative;
    }
    @each $name, $color in $colors {
      &--#{$name}::before {
        background-color: $color;
      }
    }
  }

  &--gray {
    position: relative;
    background-color: $color-gray-1;

    &::before,
    &::after {
      content: '';

      position: absolute;
      top: 0;

      width: var(--p-inline);
      height: 100%;

      background-color: $color-gray-1;
    }

    &::before {
      left: calc(var(--p-inline) * -1);
    }

    &::after {
      right: calc(var(--p-inline) * -1);
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__title {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
    }

    .title {
      margin-top: 50px;
    }
  }
  @include tzar-breakpoint-down(lg) {
    padding-block: 75px;
  }
  @include tzar-breakpoint-down(md) {
    padding-block: 65px;
  }
  @include tzar-breakpoint-down(sm) {
    &__title {
      font-size: 16px;
    }
  }
}

.grid {
  $border-width: 2px;

  position: absolute;
  top: 0;
  left: 0;

  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: 1fr;

  width: 100%;
  min-height: 100%;

  &__item {
    border-inline: solid rgba(var(--color-gray), 0.5);
    border-inline-width: calc($border-width / 2);

    &:first-child {
      border-inline-start-width: $border-width;
    }

    &:last-child {
      border-inline-end-width: $border-width;
    }
  }
}

.title {
  position: relative;
  margin-block: 0;
  font-weight: 400;
  line-height: 1.1;

  span {
    display: block;
    width: fit-content;
    text-transform: uppercase;
  }

  &--xl {
    font-size: 128px;
    @include tzar-breakpoint-down(xxl) {
      font-size: 98px;
    }
    @include tzar-breakpoint-down(xl) {
      font-size: 88px;
    }
    @include tzar-breakpoint-down(lg) {
      font-size: 98px;
    }
    @include tzar-breakpoint-down(md) {
      font-size: 70px;
    }
    @include tzar-breakpoint-down(sm) {
      font-size: 62px;
    }
  }

  &--lg {
    font-size: clamp(32px, 4.5vw, 80px);
  }

  &--md {
    font-size: clamp(28px, 3.2vw, 50px);
  }

  &--sm {
    font-size: clamp(24px, 3vw, 40px);
  }

  &--xs {
    font-size: clamp(14px, 2vw, 22px);
    font-weight: 500;
  }

  &--orange {
    color: $color-orange;
  }

  &--black {
    color: $color-black;
  }

  &--yellow {
    color: $color-yellow;
  }
}

.lead {
  --box-size: 34px;

  position: relative;
  padding-right: 5%;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: -16px;
    translate: -100% 0;

    width: var(--box-size);
    height: var(--box-size);

    background-color: $color-red;
  }
  @include tzar-breakpoint-down(xxl) {
    --box-size: 26px;
  }
  @include tzar-breakpoint-down(xl) {
    --box-size: 22px;
  }
  @include tzar-breakpoint-down(md) {
    --box-size: 18px;
  }
  @include tzar-breakpoint-down(sm) {
    --box-size: 16px;

    &::before {
      left: -10px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.link {
  position: relative;
  width: fit-content;
  padding-inline-end: 22px;
  transition: color 0.2s ease;

  &::after {
    content: '';

    position: absolute;
    top: 50%;
    right: 0;
    translate: 0 -50%;

    width: 16px;
    height: 16px;

    background-image: url('../images/icons/link.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  @include hover {
    color: $color-orange;
  }
}
