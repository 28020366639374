.force {
  position: relative;
  grid-template-columns: repeat(8, 1fr);

  &::before {
    content: '';

    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;

    width: 53.646vw;
    height: 100%;
    margin-right: calc(var(--p-inline) * -1);

    background-color: $color-gray-1;
  }

  &__title {
    overflow: hidden;
    grid-area: 1 / 6 / 2 / 9;
    margin-block-end: 48px;
    font-size: clamp(70px, 4.167vw, 80px);

    span:nth-child(2),
    span:nth-child(3) {
      font-size: clamp(43px, 2.604vw, 50px);
    }
  }

  &__text {
    grid-area: 2 / 6 / 3 / 9;
    margin-block-end: 68px;
    font-size: clamp(14px, 1.2vw, 22px);
    line-height: 1.4;
  }

  &__link > * {
    transition: fill 0.2s ease;
  }

  &__link:hover > * {
    fill: black;
  }

  &__pic-1 {
    grid-area: 3 / 6 / 4 / 9;

    img {
      width: 100%;
    }
  }

  &__pic-2 {
    position: relative;

    display: flex;
    grid-area: 1 / 2 / 4 / 5;

    width: 34.896vw;
    max-width: 670px;
    height: 41.667vw;
    max-height: 800px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: -11vw;
    }
  }
  @include tzar-breakpoint-down(xxl) {
    &__title {
      font-size: 4.167vw;

      span:nth-child(2),
      span:nth-child(3) {
        font-size: 2.604vw;
      }
    }
  }
  @include tzar-breakpoint-down(xl) {
    &__text {
      font-size: 14px;
    }

    &__pic-2 {
      width: 34.833vw;
    }
  }
  @include tzar-breakpoint-down(lg) {
    grid-template-columns: repeat(var(--columns), 1fr);
    grid-template-rows: repeat(3, auto);

    &::before {
      width: 100vw;
      height: 80%;
      margin-left: calc(var(--p-inline) * -1);
    }

    &__title {
      grid-area: 1 / 1 / 2 / 5;
      margin-top: 30px;
      margin-block-end: 40px;
      font-size: 6.72vw;

      span:nth-child(2),
      span:nth-child(3) {
        font-size: 4.301vw;
      }
    }

    &__text {
      grid-area: 2 / 1 / 3 / 3;
      margin-block-end: 0;
      padding-right: 11vw;
    }

    &__pic-1 {
      grid-area: 2 / 3 / 3 / 5;
    }

    &__pic-2 {
      grid-area: 3 / 1 / 4 / 5;

      width: 100%;
      max-width: unset;
      height: 300px;
      max-height: unset;
      margin-block-start: 40px;

      img {
        object-position: 0;
      }
    }
  }
  @include tzar-breakpoint-down(sm) {
    &__title {
      grid-area: 1 / 1 / 2 / 3;
      margin-block-end: 32px;
      font-size: 11.111vw;

      span:nth-child(2),
      span:nth-child(3) {
        font-size: 8.333vw;
      }
    }

    &__text {
      grid-area: 2 / 1 / 3 / 3;
      margin-block-end: 28px;
      padding-right: 0;
    }

    &__pic-1 {
      grid-area: 3 / 1 / 4 / 3;
    }

    &__pic-2 {
      grid-area: 4 / 1 / 5 / 3;
      height: 256px;
    }
  }
}

.force__title span {
  &:nth-child(2) {
    translate: 10%;
  }

  &:nth-child(3) {
    translate: 20%;
  }
  @include tzar-breakpoint-down(sm) {
    &:nth-child(2),
    &:nth-child(3) {
      translate: 20px 0;
    }
  }
}
