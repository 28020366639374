.footer {
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  padding-block: 26px;
  border-top: 1px solid $color-gray-2;

  &__copyright {
    grid-area: 1 / 2 / 2 / 3;
  }

  &__social {
    grid-area: 1 / 4 / 2 / 5;
  }

  &__contacts {
    display: flex;
    grid-area: 1 / -2 / 2 / -1;
    flex-direction: column;
    gap: 3px;

    text-align: end;

    p,
    a {
      margin: 0;
      line-height: 1;
      color: $color-text;
    }

    a {
      @include hover {
        text-decoration: underline;
      }
    }
  }

  address,
  p,
  a {
    font-size: 1rem;
    font-style: normal;
    color: $color-gray-3;
  }
  @include tzar-breakpoint-down(lg) {
    &__copyright {
      grid-area: 1 / 1 / 2 / 2;
    }

    &__social {
      grid-area: 1 / 3 / 2 / 4;
    }

    &__contacts {
      grid-area: 1 / 4 / 2 / -1;
    }
  }
  @include tzar-breakpoint-down(sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    address,
    p,
    a {
      font-size: 14px;
    }

    &__social {
      display: flex;
      justify-content: end;
      width: 40%;
    }

    &__contacts {
      gap: 12px;
      order: -1;
      width: 60%;
      text-align: start;
    }

    &__copyright {
      order: 1;

      width: 100%;
      margin-top: 10px;
      margin-right: auto;

      text-align: start;
      text-wrap: balance;
    }
  }
}

.social {
  &__list {
    display: flex;
    gap: 28px;
    align-items: center;
  }

  &__item {
    display: flex;
  }

  &__link {
    width: 36px;
    height: 36px;
    @include hover {
      svg path {
        fill: $color-orange;
      }
    }
  }
  @include tzar-breakpoint-down(xl) {
    &__link {
      width: 22px;
      height: 22px;
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__list {
      gap: 16px;
      align-items: flex-start;
    }
  }
}
