.pros {
  position: relative;
  grid-template-rows: repeat(4, auto);

  &::before {
    content: '';

    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100%;
    margin-left: calc(var(--p-inline) * -1);

    background-image: url('../images/icons/balmax/bg.svg');
  }

  &__title {
    position: relative;

    overflow: hidden;
    grid-area: 1 / 2 / 2 / 7;

    margin-block-end: 60px;

    font-size: 80px;
  }

  &__title--mob {
    display: none;
  }

  &__content {
    translate: 100px;
    grid-area: 2 / 2 / 3 / 6;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 30px 200px;

    font-size: 22px;
    line-height: 1.4;
  }

  &__item::marker {
    content: none;
    font-size: 0;
  }

  &__item span {
    position: relative;
    display: block;
    padding-left: 40px;

    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;

      width: 30px;
      height: 30px;

      background-image: url('../images/icons/balmax/li-item.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  &__assets {
    display: grid;
    grid-area: 3 / 2 / 4 / -1;
    grid-template-columns: repeat(4, 1fr);
    margin-block-start: 100px;
  }

  &__pic {
    grid-area: 4 / 1 / 5 / -1;
    margin-block-start: 120px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @include tzar-breakpoint-down(xxl) {
    &__title {
      font-size: 4.167vw;
    }

    &__list {
      gap: 30px 5vw;
    }

    &__assets {
      grid-area: 3 / 1 / 4 / -1;
    }
  }
  @include tzar-breakpoint-down(xl) {
    &__title {
      margin-block-end: 38px;
    }

    &__list {
      font-size: 14px;
    }

    &__item span {
      padding-inline: 26px 10px;

      &::before {
        width: 20px;
        height: 20px;
      }
    }

    &__assets {
      margin-block-start: 60px;
    }

    &__pic {
      height: 506px;
      margin-block-start: 73px;
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__title {
      grid-area: 1 / 1 / 2 / -1;
      font-size: 6.72vw;
    }

    &__content {
      translate: 0;
      grid-area: 2 / 1 / 3 / 5;
    }

    &__assets {
      grid-area: 3 / 1 / 4 / -1;
      margin-block-start: 40px;
    }

    &__pic {
      height: 350px;
    }
  }
  @include tzar-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;

    &::before {
      content: none;
    }

    .section__title {
      top: 15px;
    }

    &__title {
      margin-top: 40px !important;
      margin-block-end: 36px;
      font-size: 10vw;
    }

    &__title--desk {
      display: none;
    }

    &__title--mob {
      display: block;
    }

    &__pic {
      position: relative;
      height: 256px;
      margin-block-start: 50px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
    }

    &__assets {
      grid-area: 3 / 1 / 4 / -1;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.pros__title span {
  &:nth-child(2) {
    translate: 40px;
  }

  &:nth-child(3) {
    translate: 100px;
    font-size: 40px;
  }
  @include tzar-breakpoint-down(xxl) {
    &:nth-child(2) {
      translate: 2.083vw;
    }

    &:nth-child(3) {
      translate: 5.208vw;
      font-size: 2.083vw;
    }
  }
  @include tzar-breakpoint-down(lg) {
    &:nth-child(2) {
      translate: 3.083vw;
    }

    &:nth-child(3) {
      translate: 3.6vw;
      font-size: 3.36vw;
    }
  }
  @include tzar-breakpoint-down(sm) {
    &:nth-child(2) {
      translate: 6vw;
      font-size: 6.667vw;
    }

    &:nth-child(3) {
      translate: 12vw;
      font-size: 6.667vw;
    }

    &:nth-child(4),
    &:nth-child(5) {
      translate: 20vw;
      font-size: 6.667vw;
    }
  }
}

.pros .assets {
  position: relative;
  background-color: $color-gray-1;

  &__item {
    position: static;

    margin-block-end: 0;
    padding-block: 50px;
    padding-inline: 10%;

    font-size: clamp(14px, 1.5vw, 22px);
    line-height: 1.3;

    &:first-child {
      padding-inline: 46px 10%;

      font-size: clamp(14px, 1.3vw, 25px);
      font-weight: 600;
      line-height: 1.2;
      color: white;
      text-transform: uppercase;

      background-color: $color-yellow;

      &::before {
        content: '';

        position: absolute;
        z-index: 1;
        top: 20px;
        bottom: 20px;
        left: -1px;

        width: 20px;

        background-color: white;
      }
    }

    &:last-child {
      padding-inline-end: 46px;

      &::before {
        content: '';

        position: absolute;
        z-index: 1;
        top: 20px;
        right: -1px;
        bottom: 20px;

        width: 20px;

        background-color: white;
      }
    }

    span {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  &__link::after {
    width: 1em;
    height: 1em;
  }
  @include tzar-breakpoint-down(md) {
    &__item {
      padding-block: 30px;
      padding-inline: 8%;

      &:first-child {
        padding-block: 30px;
        padding-inline: 28px 8%;

        &::before {
          top: 12px;
          bottom: 12px;
          width: 10px;
        }
      }

      &:last-child {
        padding-inline-end: 28px;

        &::before {
          top: 12px;
          bottom: 12px;
          width: 10px;
        }
      }
    }
  }
  @include tzar-breakpoint-down(sm) {
    &__item {
      min-height: 135px;
      padding-block: 24px;
      padding-inline: 12px;

      &:nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;
        padding-inline-end: 20px;
      }

      &:nth-child(3) {
        grid-area: 2 / 1 / 3 / 2;
        padding-inline-start: 20px;
      }

      &:nth-child(4) {
        grid-area: 2 / 2 / 3 / 3;
        padding-inline-end: 20px;
      }
    }
  }
}
