.front {
  position: relative;
  height: 100svh;
  padding-block: 0;

  &__pic {
    position: absolute;
    z-index: -1;
    top: 0;
    left: calc(var(--p-inline) * -1);

    overflow: hidden;
    display: flex;

    width: calc(100% + (var(--p-inline) * 2));
    height: 100%;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__text {
    display: grid;
    grid-area: 2 / 1 / 3 / 5;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);

    margin-top: auto;
    padding-block-end: 30px;
  }

  &__subtitle {
    position: relative;

    grid-area: unset;

    width: fit-content;
    margin: auto 0 35px;

    font-size: 32px;
    font-weight: 400;
    line-height: 1;
    color: white;
    white-space: nowrap;

    span {
      // display: block;
      padding-block: 4px 7px;
      padding-inline: 22px;
      background-color: $color-orange;

      &.front__subtitle-text-desk {
        display: block;
      }

      &.front__subtitle-text-mob {
        display: none;
      }
    }
  }

  &__title {
    position: relative;

    grid-area: 2 / 1 / 3 / -1;

    width: 65.677vw;
    margin: 0;

    font-size: clamp(38px, 13.4vw, 250px);
    line-height: 1;
    color: white;

    img {
      position: static;
    }
  }

  &__note {
    grid-area: 1 / -2 / 3 / -1;
    margin-block: auto 0;
    padding-block: 0;
    padding-inline: 0;
  }

  &__note-wrap {
    display: flex;
    flex-direction: column;
    background: rgba(var(--color-gray), 0.2);
    backdrop-filter: blur(8px);
  }

  &__note-img {
    display: flex;

    width: 100%;
    margin-block-end: -16px;
    padding-block: 22px 0;
    padding-inline: 22px;

    img {
      z-index: 1;
      width: 100%;
      object-fit: cover;
    }
  }

  &__note-text {
    flex-grow: 1;

    margin-block-end: 0;
    padding-block: 46px 36px;
    padding-inline: 22px;

    font-size: 22px;
    color: white;

    background-color: #313131;
  }
  @include tzar-breakpoint-down(xl) {
    &__subtitle {
      font-size: 20px;

      span {
        padding-block: 2px 4px;
        padding-inline: 14px;
      }
    }

    &__note-img {
      margin-block-end: -10px;
      padding-block: 14px 0;
      padding-inline: 14px;
    }

    &__note-text {
      padding-block: 28px 20px;
      padding-inline: 14px;
      font-size: 14px;
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__text {
      grid-area: 1 / 1 / 3 / 4;
      grid-template-columns: repeat(3, 1fr);
      margin: 0;
      padding: 0;
    }

    &__title {
      margin-block: auto 0;
    }

    &__subtitle {
      margin-block: 160px auto;
      font-size: 44px;

      span {
        width: fit-content;
        padding-inline: 12px;

        &.front__subtitle-text-desk {
          display: none;
        }

        &.front__subtitle-text-mob {
          display: block;
        }
      }
    }

    .front__pic img {
      object-position: 90%;
    }
  }
  @include tzar-breakpoint-down(md) {
    .front__pic img {
      object-position: 80%;
    }
  }
  @include tzar-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;

    &__note {
      position: absolute;
      right: 0;
      bottom: 140px;

      width: 50%;
      min-height: auto;
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      height: 100%;
      padding-top: 100px;
    }

    &__subtitle {
      margin: 0;
      font-size: 32px;
    }

    &__title {
      width: auto;
      margin: 0;
    }
  }
  @include tzar-breakpoint-down(xs) {
    &__note {
      bottom: 80px;
    }

    &__note-text {
      font-size: 14px;
    }
  }
}
