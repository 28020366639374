html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

a {
  --link-color: $ color-text;
  --link-color_visited: $ color-text;
  --link-color_hover: $ color-text;
  --link-color_disabled: $ color-text;
  color: var(--link-color);
  text-decoration: none;
}

a:visited {
  color: var(--link-color_visited);
}

a:hover, a:active {
  color: var(--link-color_hover);
}

a:disabled, a.disabled {
  pointer-events: none;
  color: var(--link-color_disabled);
}

.external-link {
  --external-link-color: var(--var_3);
  --external-link-color_visited: var(--var_5);
  --external-link-color_hover: var(--var_1);
  --external-link-color_disabled: var(--var_4);
  cursor: alias;
  color: var(--external-link-color);
  text-decoration: underline;
}

.external-link:visited {
  color: var(--external-link-color_visited);
}

.external-link:hover, .external-link:active {
  color: var(--external-link-color_hover);
  text-decoration: none;
}

.external-link:disabled, .external-link.disabled {
  pointer-events: none;
  color: var(--external-link-color_visited);
}

button {
  --btn-color: var(--var_6);
  --btn-bg: var(--var_1);
  --btn-border: var(--var_6);
  --btn-color_hover: var(--var_7);
  --btn-bg_hover: var(--var_8);
  --btn-border_hover: var(--var_7);
  --btn-color_disabled: var(--var_1);
  --btn-bg_disabled: var(--var_5);
  --btn-border_disabled: var(--var_1);
  cursor: pointer;
  color: var(--btn-color);
  background-color: var(--btn-bg);
  border: 2px solid var(--btn-border);
}

button:hover, button:active {
  color: var(--btn-color_hover);
  background-color: var(--btn-bg_hover);
  border-color: var(--btn-border_hover);
}

button:disabled, button.disabled {
  pointer-events: none;
  background-color: var(--btn-bg_disabled);
}

.button-light {
  --btn-color: #5fffff;
  --btn-bg: #979393;
  --btn-border: #000;
  --btn-color_hover: #979393;
  --btn-bg_hover: #5fffff;
  --btn-color_disabled: #5fffff;
  --btn-bg_disabled: #979393;
  --btn-border_disabled: #000;
}

.button-light:disabled, .button-light.disabled {
  opacity: .4;
}

.button-dark {
  --btn-color: #fff;
  --btn-bg: #000;
  --btn-border: #fff;
  --btn-color_hover: #000;
  --btn-bg_hover: #fff;
  --btn-border_hover: #000;
  --btn-color_disabled: #fff;
  --btn-bg_disabled: #000;
  --btn-border_disabled: #fff;
}

.button-dark:disabled, .button-dark.disabled {
  opacity: .4;
}

:root {
  --tzar-breakpoint-mobile: 0;
  --tzar-breakpoint-xs: 499px;
  --tzar-breakpoint-sm: 699px;
  --tzar-breakpoint-md: 889px;
  --tzar-breakpoint-lg: 1191px;
  --tzar-breakpoint-xl: 1401px;
  --tzar-breakpoint-xxl: 1681px;
  --tzar-breakpoint-ultra: 1921px;
  --tzar-gutter: 24px;
  --grid-breakpoint-mobile: 0;
  --grid-breakpoint-xs: 399px;
  --grid-breakpoint-sm: 699px;
  --grid-breakpoint-md: 889px;
  --grid-breakpoint-lg: 1191px;
  --grid-breakpoint-xl: 1401px;
  --grid-breakpoint-xxl: 1681px;
  --grid-breakpoint-ultra: 1921px;
  --grid-gutter: 24px;
}

.tzar-row {
  box-sizing: border-box;
  margin-right: calc(-.5 * var(--tzar-gutter, 24px));
  margin-left: calc(-.5 * var(--tzar-gutter, 24px));
  flex-flow: wrap;
  flex: 0 auto;
  display: flex;
}

.tzar-row > * {
  padding-right: calc(.5 * var(--tzar-gutter, 24px));
  padding-left: calc(.5 * var(--tzar-gutter, 24px));
  flex: 0 auto;
}

.tzar-col {
  flex: 1 0;
}

.tzar-col-1 {
  flex: none;
  width: 8.33333%;
}

.tzar-col-2 {
  flex: none;
  width: 16.6667%;
}

.tzar-col-3 {
  flex: none;
  width: 25%;
}

.tzar-col-4 {
  flex: none;
  width: 33.3333%;
}

.tzar-col-5 {
  flex: none;
  width: 41.6667%;
}

.tzar-col-6 {
  flex: none;
  width: 50%;
}

.tzar-col-7 {
  flex: none;
  width: 58.3333%;
}

.tzar-col-8 {
  flex: none;
  width: 66.6667%;
}

.tzar-col-9 {
  flex: none;
  width: 75%;
}

.tzar-col-10 {
  flex: none;
  width: 83.3333%;
}

.tzar-col-11 {
  flex: none;
  width: 91.6667%;
}

.tzar-col-12 {
  flex: none;
  width: 100%;
}

.tzar-offset-1 {
  margin-left: 8.33333%;
}

.tzar-offset-2 {
  margin-left: 16.6667%;
}

.tzar-offset-3 {
  margin-left: 25%;
}

.tzar-offset-4 {
  margin-left: 33.3333%;
}

.tzar-offset-5 {
  margin-left: 41.6667%;
}

.tzar-offset-6 {
  margin-left: 50%;
}

.tzar-offset-7 {
  margin-left: 58.3333%;
}

.tzar-offset-8 {
  margin-left: 66.6667%;
}

.tzar-offset-9 {
  margin-left: 75%;
}

.tzar-offset-10 {
  margin-left: 83.3333%;
}

.tzar-offset-11 {
  margin-left: 91.6667%;
}

.no-gutter {
  --tzar-gutter: 0;
}

@media (width <= 498.98px) {
  .tzar-col-xs-1 {
    flex: none;
    width: 8.33333%;
  }

  .tzar-col-xs-2 {
    flex: none;
    width: 16.6667%;
  }

  .tzar-col-xs-3 {
    flex: none;
    width: 25%;
  }

  .tzar-col-xs-4 {
    flex: none;
    width: 33.3333%;
  }

  .tzar-col-xs-5 {
    flex: none;
    width: 41.6667%;
  }

  .tzar-col-xs-6 {
    flex: none;
    width: 50%;
  }

  .tzar-col-xs-7 {
    flex: none;
    width: 58.3333%;
  }

  .tzar-col-xs-8 {
    flex: none;
    width: 66.6667%;
  }

  .tzar-col-xs-9 {
    flex: none;
    width: 75%;
  }

  .tzar-col-xs-10 {
    flex: none;
    width: 83.3333%;
  }

  .tzar-col-xs-11 {
    flex: none;
    width: 91.6667%;
  }

  .tzar-col-xs-12 {
    flex: none;
    width: 100%;
  }

  .tzar-offset-xs-0 {
    margin-left: 0;
  }

  .tzar-offset-xs-1 {
    margin-left: 8.33333%;
  }

  .tzar-offset-xs-2 {
    margin-left: 16.6667%;
  }

  .tzar-offset-xs-3 {
    margin-left: 25%;
  }

  .tzar-offset-xs-4 {
    margin-left: 33.3333%;
  }

  .tzar-offset-xs-5 {
    margin-left: 41.6667%;
  }

  .tzar-offset-xs-6 {
    margin-left: 50%;
  }

  .tzar-offset-xs-7 {
    margin-left: 58.3333%;
  }

  .tzar-offset-xs-8 {
    margin-left: 66.6667%;
  }

  .tzar-offset-xs-9 {
    margin-left: 75%;
  }

  .tzar-offset-xs-10 {
    margin-left: 83.3333%;
  }

  .tzar-offset-xs-11 {
    margin-left: 91.6667%;
  }

  .no-gutter-xs {
    --tzar-gutter: 0;
  }
}

@media (width <= 698.98px) {
  .tzar-col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .tzar-col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .tzar-col-sm-3 {
    flex: none;
    width: 25%;
  }

  .tzar-col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .tzar-col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .tzar-col-sm-6 {
    flex: none;
    width: 50%;
  }

  .tzar-col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .tzar-col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .tzar-col-sm-9 {
    flex: none;
    width: 75%;
  }

  .tzar-col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .tzar-col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .tzar-col-sm-12 {
    flex: none;
    width: 100%;
  }

  .tzar-offset-sm-0 {
    margin-left: 0;
  }

  .tzar-offset-sm-1 {
    margin-left: 8.33333%;
  }

  .tzar-offset-sm-2 {
    margin-left: 16.6667%;
  }

  .tzar-offset-sm-3 {
    margin-left: 25%;
  }

  .tzar-offset-sm-4 {
    margin-left: 33.3333%;
  }

  .tzar-offset-sm-5 {
    margin-left: 41.6667%;
  }

  .tzar-offset-sm-6 {
    margin-left: 50%;
  }

  .tzar-offset-sm-7 {
    margin-left: 58.3333%;
  }

  .tzar-offset-sm-8 {
    margin-left: 66.6667%;
  }

  .tzar-offset-sm-9 {
    margin-left: 75%;
  }

  .tzar-offset-sm-10 {
    margin-left: 83.3333%;
  }

  .tzar-offset-sm-11 {
    margin-left: 91.6667%;
  }

  .no-gutter-sm {
    --tzar-gutter: 0;
  }
}

@media (width <= 888.98px) {
  .tzar-col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .tzar-col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .tzar-col-md-3 {
    flex: none;
    width: 25%;
  }

  .tzar-col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .tzar-col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .tzar-col-md-6 {
    flex: none;
    width: 50%;
  }

  .tzar-col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .tzar-col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .tzar-col-md-9 {
    flex: none;
    width: 75%;
  }

  .tzar-col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .tzar-col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .tzar-col-md-12 {
    flex: none;
    width: 100%;
  }

  .tzar-offset-md-0 {
    margin-left: 0;
  }

  .tzar-offset-md-1 {
    margin-left: 8.33333%;
  }

  .tzar-offset-md-2 {
    margin-left: 16.6667%;
  }

  .tzar-offset-md-3 {
    margin-left: 25%;
  }

  .tzar-offset-md-4 {
    margin-left: 33.3333%;
  }

  .tzar-offset-md-5 {
    margin-left: 41.6667%;
  }

  .tzar-offset-md-6 {
    margin-left: 50%;
  }

  .tzar-offset-md-7 {
    margin-left: 58.3333%;
  }

  .tzar-offset-md-8 {
    margin-left: 66.6667%;
  }

  .tzar-offset-md-9 {
    margin-left: 75%;
  }

  .tzar-offset-md-10 {
    margin-left: 83.3333%;
  }

  .tzar-offset-md-11 {
    margin-left: 91.6667%;
  }

  .no-gutter-md {
    --tzar-gutter: 0;
  }
}

@media (width <= 1190.98px) {
  .tzar-col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .tzar-col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .tzar-col-lg-3 {
    flex: none;
    width: 25%;
  }

  .tzar-col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .tzar-col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .tzar-col-lg-6 {
    flex: none;
    width: 50%;
  }

  .tzar-col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .tzar-col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .tzar-col-lg-9 {
    flex: none;
    width: 75%;
  }

  .tzar-col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .tzar-col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .tzar-col-lg-12 {
    flex: none;
    width: 100%;
  }

  .tzar-offset-lg-0 {
    margin-left: 0;
  }

  .tzar-offset-lg-1 {
    margin-left: 8.33333%;
  }

  .tzar-offset-lg-2 {
    margin-left: 16.6667%;
  }

  .tzar-offset-lg-3 {
    margin-left: 25%;
  }

  .tzar-offset-lg-4 {
    margin-left: 33.3333%;
  }

  .tzar-offset-lg-5 {
    margin-left: 41.6667%;
  }

  .tzar-offset-lg-6 {
    margin-left: 50%;
  }

  .tzar-offset-lg-7 {
    margin-left: 58.3333%;
  }

  .tzar-offset-lg-8 {
    margin-left: 66.6667%;
  }

  .tzar-offset-lg-9 {
    margin-left: 75%;
  }

  .tzar-offset-lg-10 {
    margin-left: 83.3333%;
  }

  .tzar-offset-lg-11 {
    margin-left: 91.6667%;
  }

  .no-gutter-lg {
    --tzar-gutter: 0;
  }
}

@media (width <= 1400.98px) {
  .tzar-col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .tzar-col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .tzar-col-xl-3 {
    flex: none;
    width: 25%;
  }

  .tzar-col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .tzar-col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .tzar-col-xl-6 {
    flex: none;
    width: 50%;
  }

  .tzar-col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .tzar-col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .tzar-col-xl-9 {
    flex: none;
    width: 75%;
  }

  .tzar-col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .tzar-col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .tzar-col-xl-12 {
    flex: none;
    width: 100%;
  }

  .tzar-offset-xl-0 {
    margin-left: 0;
  }

  .tzar-offset-xl-1 {
    margin-left: 8.33333%;
  }

  .tzar-offset-xl-2 {
    margin-left: 16.6667%;
  }

  .tzar-offset-xl-3 {
    margin-left: 25%;
  }

  .tzar-offset-xl-4 {
    margin-left: 33.3333%;
  }

  .tzar-offset-xl-5 {
    margin-left: 41.6667%;
  }

  .tzar-offset-xl-6 {
    margin-left: 50%;
  }

  .tzar-offset-xl-7 {
    margin-left: 58.3333%;
  }

  .tzar-offset-xl-8 {
    margin-left: 66.6667%;
  }

  .tzar-offset-xl-9 {
    margin-left: 75%;
  }

  .tzar-offset-xl-10 {
    margin-left: 83.3333%;
  }

  .tzar-offset-xl-11 {
    margin-left: 91.6667%;
  }

  .no-gutter-xl {
    --tzar-gutter: 0;
  }
}

@media (width <= 1680.98px) {
  .tzar-col-xxl-1 {
    flex: none;
    width: 8.33333%;
  }

  .tzar-col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .tzar-col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .tzar-col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .tzar-col-xxl-5 {
    flex: none;
    width: 41.6667%;
  }

  .tzar-col-xxl-6 {
    flex: none;
    width: 50%;
  }

  .tzar-col-xxl-7 {
    flex: none;
    width: 58.3333%;
  }

  .tzar-col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }

  .tzar-col-xxl-9 {
    flex: none;
    width: 75%;
  }

  .tzar-col-xxl-10 {
    flex: none;
    width: 83.3333%;
  }

  .tzar-col-xxl-11 {
    flex: none;
    width: 91.6667%;
  }

  .tzar-col-xxl-12 {
    flex: none;
    width: 100%;
  }

  .tzar-offset-xxl-0 {
    margin-left: 0;
  }

  .tzar-offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .tzar-offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .tzar-offset-xxl-3 {
    margin-left: 25%;
  }

  .tzar-offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .tzar-offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .tzar-offset-xxl-6 {
    margin-left: 50%;
  }

  .tzar-offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .tzar-offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .tzar-offset-xxl-9 {
    margin-left: 75%;
  }

  .tzar-offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .tzar-offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .no-gutter-xxl {
    --tzar-gutter: 0;
  }
}

@media (width >= 1920.98px) {
  .tzar-col-ultra-1 {
    flex: none;
    width: 8.33333%;
  }

  .tzar-col-ultra-2 {
    flex: none;
    width: 16.6667%;
  }

  .tzar-col-ultra-3 {
    flex: none;
    width: 25%;
  }

  .tzar-col-ultra-4 {
    flex: none;
    width: 33.3333%;
  }

  .tzar-col-ultra-5 {
    flex: none;
    width: 41.6667%;
  }

  .tzar-col-ultra-6 {
    flex: none;
    width: 50%;
  }

  .tzar-col-ultra-7 {
    flex: none;
    width: 58.3333%;
  }

  .tzar-col-ultra-8 {
    flex: none;
    width: 66.6667%;
  }

  .tzar-col-ultra-9 {
    flex: none;
    width: 75%;
  }

  .tzar-col-ultra-10 {
    flex: none;
    width: 83.3333%;
  }

  .tzar-col-ultra-11 {
    flex: none;
    width: 91.6667%;
  }

  .tzar-col-ultra-12 {
    flex: none;
    width: 100%;
  }

  .tzar-offset-ultra-0 {
    margin-left: 0;
  }

  .tzar-offset-ultra-1 {
    margin-left: 8.33333%;
  }

  .tzar-offset-ultra-2 {
    margin-left: 16.6667%;
  }

  .tzar-offset-ultra-3 {
    margin-left: 25%;
  }

  .tzar-offset-ultra-4 {
    margin-left: 33.3333%;
  }

  .tzar-offset-ultra-5 {
    margin-left: 41.6667%;
  }

  .tzar-offset-ultra-6 {
    margin-left: 50%;
  }

  .tzar-offset-ultra-7 {
    margin-left: 58.3333%;
  }

  .tzar-offset-ultra-8 {
    margin-left: 66.6667%;
  }

  .tzar-offset-ultra-9 {
    margin-left: 75%;
  }

  .tzar-offset-ultra-10 {
    margin-left: 83.3333%;
  }

  .tzar-offset-ultra-11 {
    margin-left: 91.6667%;
  }

  .no-gutter-ultra {
    --tzar-gutter: 0;
  }
}

:root {
  --color-gray: 222, 222, 222;
  --p-inline: 100px;
  --header-height: 56px;
  --header-z: 10;
  --footer-height: 42px;
  --footer-z: 10;
  --page-gutters: 24px;
  --aside-width: 300px;
  --nav-width: 80px;
  --nav-z: 9;
  --columns: 6;
}

@media (width <= 1680.98px) {
  :root {
    --p-inline: 70px;
  }
}

@media (width <= 1400.98px) {
  :root {
    --p-inline: 60px;
  }
}

@media (width <= 1190.98px) {
  :root {
    --columns: 4;
    --p-inline: 40px;
  }
}

@media (width <= 888.98px) {
  :root {
    --p-inline: 20px;
  }
}

@media (width <= 698.98px) {
  :root {
    --columns: 2;
    --p-inline: 14px;
  }
}

@font-face {
  font-family: GTEestiProText;
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: url("GTEestiProText-100.f3d032e9.woff2") format("woff2"), url("GTEestiProText-100.7461b81d.woff") format("woff");
}

@font-face {
  font-family: GTEestiProText;
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url("GTEestiProText-200.32c465e7.woff2") format("woff2"), url("GTEestiProText-200.a80db9a0.woff") format("woff");
}

@font-face {
  font-family: GTEestiProText;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("GTEestiProText-300.ab6de8e7.woff2") format("woff2"), url("GTEestiProText-300.d5aac7ff.woff") format("woff");
}

@font-face {
  font-family: GTEestiProText;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("GTEestiProText-400.528784db.woff2") format("woff2"), url("GTEestiProText-400.bd56ec30.woff") format("woff");
}

@font-face {
  font-family: GTEestiProText;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("GTEestiProText-400.528784db.woff2") format("woff2"), url("GTEestiProText-400.bd56ec30.woff") format("woff");
}

@font-face {
  font-family: GTEestiProText;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("GTEestiProText-700.24c86d8d.woff2") format("woff2"), url("GTEestiProText-700.d1a7e790.woff") format("woff");
}

@font-face {
  font-family: GTEestiProText;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("GTEestiProText-900.f0bc7ac1.woff2") format("woff2"), url("GTEestiProText-900.06bfae43.woff") format("woff");
}

html {
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-size: 18px;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  padding: 0;
  padding-inline: var(--p-inline);
  color: #000;
  background-color: #fff;
  min-height: 100vh;
  margin: 0;
  font-family: GTEestiProText, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  position: relative;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.visually-hidden {
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

p {
  color: #2d2f33;
  margin-block-start: 0;
  position: relative;
}

ul, li {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

h1 {
  margin-block-start: 0;
  position: relative;
}

.section {
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: auto;
  height: auto;
  padding-block: 90px;
  display: grid;
  position: relative;
}

.section__title {
  color: #00000080;
  grid-area: 1 / 1 / 2 / 2;
  height: fit-content;
  font-size: 22px;
  font-weight: 400;
  position: relative;
}

.section__title span {
  position: relative;
}

.section__title--yellow:before {
  background-color: #fab72d;
}

.section__title--orange:before {
  background-color: #ed7817;
}

.section__title--red:before {
  background-color: #e42313;
}

.section--gray {
  background-color: #f5f5f5;
  position: relative;
}

.section--gray:before, .section--gray:after {
  content: "";
  width: var(--p-inline);
  background-color: #f5f5f5;
  height: 100%;
  position: absolute;
  top: 0;
}

.section--gray:before {
  left: calc(var(--p-inline) * -1);
}

.section--gray:after {
  right: calc(var(--p-inline) * -1);
}

@media (width <= 1190.98px) {
  .section__title {
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
  }

  .section .title {
    margin-top: 50px;
  }

  .section {
    padding-block: 75px;
  }
}

@media (width <= 888.98px) {
  .section {
    padding-block: 65px;
  }
}

@media (width <= 698.98px) {
  .section__title {
    font-size: 16px;
  }
}

.grid {
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: 1fr;
  width: 100%;
  min-height: 100%;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
}

.grid__item {
  border-inline: solid rgba(var(--color-gray), .5);
  border-inline-width: 1px;
}

.grid__item:first-child {
  border-inline-start-width: 2px;
}

.grid__item:last-child {
  border-inline-end-width: 2px;
}

.title {
  margin-block: 0;
  font-weight: 400;
  line-height: 1.1;
  position: relative;
}

.title span {
  text-transform: uppercase;
  width: fit-content;
  display: block;
}

.title--xl {
  font-size: 128px;
}

@media (width <= 1680.98px) {
  .title--xl {
    font-size: 98px;
  }
}

@media (width <= 1400.98px) {
  .title--xl {
    font-size: 88px;
  }
}

@media (width <= 1190.98px) {
  .title--xl {
    font-size: 98px;
  }
}

@media (width <= 888.98px) {
  .title--xl {
    font-size: 70px;
  }
}

@media (width <= 698.98px) {
  .title--xl {
    font-size: 62px;
  }
}

.title--lg {
  font-size: clamp(32px, 4.5vw, 80px);
}

.title--md {
  font-size: clamp(28px, 3.2vw, 50px);
}

.title--sm {
  font-size: clamp(24px, 3vw, 40px);
}

.title--xs {
  font-size: clamp(14px, 2vw, 22px);
  font-weight: 500;
}

.title--orange {
  color: #ed7817;
}

.title--black {
  color: #000;
}

.title--yellow {
  color: #fab72d;
}

.lead {
  --box-size: 34px;
  padding-right: 5%;
  position: relative;
}

.lead:before {
  content: "";
  width: var(--box-size);
  height: var(--box-size);
  background-color: #e42313;
  position: absolute;
  top: 0;
  left: -16px;
  translate: -100%;
}

@media (width <= 1680.98px) {
  .lead {
    --box-size: 26px;
  }
}

@media (width <= 1400.98px) {
  .lead {
    --box-size: 22px;
  }
}

@media (width <= 888.98px) {
  .lead {
    --box-size: 18px;
  }
}

@media (width <= 698.98px) {
  .lead {
    --box-size: 16px;
  }

  .lead:before {
    left: -10px;
  }

  .lead p:last-child {
    margin-bottom: 0;
  }
}

.link {
  width: fit-content;
  padding-inline-end: 22px;
  transition: color .2s;
  position: relative;
}

.link:after {
  content: "";
  background-image: url("link.72dbc4d5.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  right: 0;
  translate: 0 -50%;
}

@media (hover: hover) {
  .link:hover {
    color: #ed7817;
  }
}

.front {
  height: 100svh;
  padding-block: 0;
  position: relative;
}

.front__pic {
  z-index: -1;
  top: 0;
  left: calc(var(--p-inline) * -1);
  width: calc(100% + var(--p-inline) * 2);
  height: 100%;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.front__pic img {
  object-fit: cover;
  width: 100%;
}

.front__text {
  grid-area: 2 / 1 / 3 / 5;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
  margin-top: auto;
  padding-block-end: 30px;
  display: grid;
}

.front__subtitle {
  grid-area: unset;
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin: auto 0 35px;
  font-size: 32px;
  font-weight: 400;
  line-height: 1;
  position: relative;
}

.front__subtitle span {
  background-color: #ed7817;
  padding-block: 4px 7px;
  padding-inline: 22px;
}

.front__subtitle span.front__subtitle-text-desk {
  display: block;
}

.front__subtitle span.front__subtitle-text-mob {
  display: none;
}

.front__title {
  color: #fff;
  grid-area: 2 / 1 / 3 / -1;
  width: 65.677vw;
  margin: 0;
  font-size: clamp(38px, 13.4vw, 250px);
  line-height: 1;
  position: relative;
}

.front__title img {
  position: static;
}

.front__note {
  grid-area: 1 / -2 / 3 / -1;
  margin-block: auto 0;
  padding-block: 0;
  padding-inline: 0;
}

.front__note-wrap {
  background: rgba(var(--color-gray), .2);
  backdrop-filter: blur(8px);
  flex-direction: column;
  display: flex;
}

.front__note-img {
  width: 100%;
  margin-block-end: -16px;
  padding-block: 22px 0;
  padding-inline: 22px;
  display: flex;
}

.front__note-img img {
  z-index: 1;
  object-fit: cover;
  width: 100%;
}

.front__note-text {
  color: #fff;
  background-color: #313131;
  flex-grow: 1;
  margin-block-end: 0;
  padding-block: 46px 36px;
  padding-inline: 22px;
  font-size: 22px;
}

@media (width <= 1400.98px) {
  .front__subtitle {
    font-size: 20px;
  }

  .front__subtitle span {
    padding-block: 2px 4px;
    padding-inline: 14px;
  }

  .front__note-img {
    margin-block-end: -10px;
    padding-block: 14px 0;
    padding-inline: 14px;
  }

  .front__note-text {
    padding-block: 28px 20px;
    padding-inline: 14px;
    font-size: 14px;
  }
}

@media (width <= 1190.98px) {
  .front__text {
    grid-area: 1 / 1 / 3 / 4;
    grid-template-columns: repeat(3, 1fr);
    margin: 0;
    padding: 0;
  }

  .front__title {
    margin-block: auto 0;
  }

  .front__subtitle {
    margin-block: 160px auto;
    font-size: 44px;
  }

  .front__subtitle span {
    width: fit-content;
    padding-inline: 12px;
  }

  .front__subtitle span.front__subtitle-text-desk {
    display: none;
  }

  .front__subtitle span.front__subtitle-text-mob {
    display: block;
  }

  .front .front__pic img {
    object-position: 90%;
  }
}

@media (width <= 888.98px) {
  .front .front__pic img {
    object-position: 80%;
  }
}

@media (width <= 698.98px) {
  .front {
    flex-direction: column;
    display: flex;
  }

  .front__note {
    width: 50%;
    min-height: auto;
    position: absolute;
    bottom: 140px;
    right: 0;
  }

  .front__text {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-top: 100px;
    display: flex;
  }

  .front__subtitle {
    margin: 0;
    font-size: 32px;
  }

  .front__title {
    width: auto;
    margin: 0;
  }
}

@media (width <= 398.98px) {
  .front__note {
    bottom: 80px;
  }

  .front__note-text {
    font-size: 14px;
  }
}

.header {
  z-index: var(--header-z);
  top: 25px;
  left: var(--p-inline);
  width: calc(100% - var(--p-inline) * 2);
  background-color: #fff;
  border-radius: 80px;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding-block: 10px;
  padding-inline: 30px;
  display: flex;
  position: fixed;
  box-shadow: 0 2px 5px #0000001a;
}

.header__logos {
  align-items: center;
  gap: 32px;
  height: 100%;
  display: flex;
  position: relative;
}

.header__logos:before {
  content: "";
  background-color: #090204;
  width: 1.5px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  translate: -50%;
}

.header__balmax, .header__evraz {
  align-items: center;
  width: 127px;
  height: 100%;
  display: flex;
}

.header__list {
  gap: 70px;
  display: flex;
}

.header__link {
  position: relative;
}

.header__link:after {
  content: "";
  background-color: currentColor;
  width: 0;
  height: 1.5px;
  transition: all .2s;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (hover: hover) {
  .header__link:hover:after {
    width: 100%;
    left: 0;
  }
}

.header img {
  object-fit: cover;
  width: 100%;
}

.header__burger {
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
  width: 32px;
  height: 15px;
  padding-inline: 6px;
}

.header__burger-line {
  background-color: #2d2f33;
  width: 20px;
  height: 2px;
  transition: all .2s ease-in-out;
  display: block;
}

@media (width >= 699px) {
  .header__burger {
    display: none;
  }
}

@media (width <= 1680.98px) {
  .header__list {
    gap: 60px;
  }
}

@media (width <= 1400.98px) {
  .header {
    height: 54px;
  }

  .header__list {
    gap: 50px;
  }
}

@media (width <= 1190.98px) {
  .header {
    height: 50px;
    padding-block: 5px;
    padding-inline: 24px;
  }

  .header__list, .header__logos {
    gap: 26px;
  }

  .header__balmax, .header__evraz {
    width: 106px;
  }
}

@media (width <= 888.98px) {
  .header {
    padding-inline: 12px;
  }

  .header__list {
    gap: 18px;
  }

  .header__link {
    font-size: 16px;
  }

  .header {
    height: 40px;
    top: 15px;
  }

  .header__logos {
    gap: 20px;
  }

  .header__balmax, .header__evraz {
    width: 96px;
  }

  .header__burger {
    display: flex;
  }

  .header__nav {
    translate: calc(100% + var(--p-inline));
    background-color: #fff;
    border-radius: 20px;
    width: auto;
    transition: all .2s ease-in-out;
    position: absolute;
    top: 60px;
    right: 0;
    box-shadow: 0 2px 5px #0000001a;
  }

  .header__list {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    padding: 10px 20px;
  }

  .menu-open .header__nav {
    translate: 0;
  }

  .menu-open .header__burger-line:first-child {
    translate: 0 6px;
    rotate: 45deg;
  }

  .menu-open .header__burger-line:nth-child(2) {
    transform: scaleX(0);
  }

  .menu-open .header__burger-line:nth-child(3) {
    translate: 0 -5px;
    rotate: -45deg;
  }
}

.tools {
  background-color: #f5f5f5;
}

.tools:before {
  content: "";
  z-index: -1;
  margin-left: calc(var(--p-inline) * -1);
  background-color: #f5f5f5;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.tools__title {
  grid-area: 1 / 2 / 2 / 6;
  margin-block-end: 42px;
  font-size: 120px;
}

.tools__balmax {
  grid-area: 2 / 2 / 3 / -1;
  grid-template-columns: repeat(5, 1fr);
  display: grid;
  position: relative;
}

@media (width <= 1680.98px) {
  .tools__title {
    font-size: 6.25vw;
  }
}

@media (width <= 1190.98px) {
  .tools__title {
    grid-area: 1 / 1 / 2 / 5;
    font-size: 10vw;
  }

  .tools__balmax {
    grid-area: 2 / 1 / 3 / -1;
    grid-template-columns: repeat(4, 1fr);
  }

  .tools__assets {
    grid-area: 3 / 1 / 4 / -1;
  }
}

@media (width <= 698.98px) {
  .tools__balmax {
    grid-area: 2 / 1 / 3 / -1;
  }

  .tools__assets {
    grid-area: 3 / 1 / 4 / -1;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 398.98px) {
  .tools__title {
    font-size: 11.111vw;
  }
}

.tools-balmax__stat {
  grid-area: 1 / 1 / 2 / 3;
  grid-template-columns: repeat(1, 1fr);
  display: grid;
}

.tools-balmax__logo {
  width: 50%;
  margin-block-end: 10px;
}

.tools-balmax__logo img {
  width: 100%;
}

.tools-balmax__value {
  margin-block-end: 38px;
  font-weight: 700;
}

.tools-balmax__pic {
  right: calc(var(--p-inline) * -1);
  grid-area: 1 / 4 / 2 / 6;
  width: 33.854vw;
  max-width: 656px;
  margin-top: -500px;
  position: absolute;
}

.tools-balmax__pic img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.tools-balmax__text {
  margin-block-end: 0;
  padding-right: 5%;
  font-size: 20px;
  line-height: 1.3;
}

@media (width <= 1680.98px) {
  .tools-balmax__pic {
    margin-top: -27.381vw;
  }

  .tools-balmax p {
    font-size: 16px;
  }
}

@media (width <= 1190.98px) {
  .tools-balmax__pic {
    width: auto;
    margin-top: 0;
    position: relative;
  }
}

@media (width <= 888.98px) {
  .tools-balmax__pic {
    grid-area: 1 / 3 / 2 / 5;
  }
}

@media (width <= 698.98px) {
  .tools-balmax__stat {
    grid-area: 1 / 1 / 2 / 5;
  }

  .tools-balmax__pic {
    grid-area: 2 / 2 / 3 / 5;
  }
}

.tools .assets {
  background-color: #fff;
  grid-area: 3 / 1 / 4 / -1;
  grid-template-columns: repeat(4, 1fr);
  margin-block-start: 44px;
  display: grid;
  position: relative;
}

.tools .assets__item {
  --i-size: 58px;
  height: 255px;
  margin-block-end: 0;
  padding-block: 30px;
  padding-inline: 10%;
  font-size: clamp(14px, 1.5vw, 22px);
  line-height: 1.3;
}

.tools .assets__item:first-child {
  padding-inline-start: 46px;
}

.tools .assets__item:first-child:after {
  content: "";
  background-color: #f5f5f5;
  width: 20px;
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: -1px;
}

.tools .assets__item:last-child {
  padding-inline-end: 46px;
}

.tools .assets__item:last-child:after {
  content: "";
  background-color: #f5f5f5;
  width: 20px;
  position: absolute;
  top: 20px;
  bottom: 20px;
  right: -1px;
}

.tools .assets__item:first-child span:before {
  background-image: url("tools-1.e0c1fbbd.svg");
}

.tools .assets__item:nth-child(2) span:before {
  background-image: url("tools-2.0ed1f278.svg");
}

.tools .assets__item:nth-child(3) span:before {
  background-image: url("tools-3.cc6dd06e.svg");
}

.tools .assets__item:nth-child(4) span:before {
  background-image: url("tools-4.aa170fa0.svg");
}

.tools .assets__item span {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  display: flex;
  position: static;
}

.tools .assets__item span:before {
  content: "";
  width: var(--i-size);
  height: var(--i-size);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

@media (width <= 1400.98px) {
  .tools .assets__item {
    --i-size: 37px;
    height: 160px;
    padding-block: 20px;
    font-size: 14px;
  }
}

@media (width <= 888.98px) {
  .tools .assets__item {
    padding-block: 30px;
    padding-inline: 8%;
  }

  .tools .assets__item:first-child {
    padding-inline-start: 28px;
  }

  .tools .assets__item:first-child:after {
    width: 10px;
    top: 12px;
    bottom: 12px;
  }

  .tools .assets__item:last-child {
    padding-inline-end: 28px;
  }

  .tools .assets__item:last-child:after {
    width: 10px;
    top: 12px;
    bottom: 12px;
  }
}

@media (width <= 698.98px) {
  .tools .assets__item {
    min-height: 135px;
    padding-block: 24px;
    padding-inline: 12px;
  }

  .tools .assets__item:first-child {
    grid-area: 1 / 1 / 2 / 3;
    padding-inline-start: 20px;
  }

  .tools .assets__item:nth-child(2) {
    grid-area: 1 / 3 / 2 / 5;
    padding-inline-end: 20px;
  }

  .tools .assets__item:nth-child(3) {
    grid-area: 2 / 1 / 3 / 3;
    padding-inline-start: 20px;
  }

  .tools .assets__item:nth-child(4) {
    grid-area: 2 / 3 / 3 / 5;
    padding-inline-end: 20px;
  }
}

.tools__title span:nth-child(2) {
  translate: 2.604vw;
}

@media (width <= 698.98px) {
  .tools__title span:nth-child(3) {
    translate: 2.604vw;
  }
}

.trust {
  position: relative;
}

.trust:before {
  content: "";
  z-index: -1;
  margin-left: calc(var(--p-inline) * -1);
  background-image: url("bg.5567cecc.svg");
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.trust__header {
  letter-spacing: .02em;
  grid-area: 1 / 2 / 2 / -1;
  margin-block-end: 50px;
  position: relative;
}

.trust__header-subtitle {
  position: absolute;
  bottom: 30px;
  left: 860px;
}

.trust__header-subtitle span {
  color: #fff;
  text-transform: uppercase;
  background-color: #ed7817;
  padding-block: .781vw .625vw;
  padding-inline: 1.25vw 2.188vw;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2;
  display: block;
}

.trust__header-subtitle span:before {
  content: "";
  background-color: #fff;
  width: .729vw;
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: -1px;
}

.trust__title {
  font-size: clamp(75px, 6.25vw, 120px);
  overflow: hidden;
}

.trust__list {
  grid-area: 2 / 1 / 3 / -1;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-block-end: 6.771vw;
  display: grid;
}

.trust__item {
  --i-size-w-1: 100px;
  --i-size-w-2: 85px;
  --i-size-w-3: 51px;
  --i-size-w-4: 87px;
  --i-size-w-5: 70px;
  --i-size-h-1: 73px;
  --i-size-h-2: 59px;
  --i-size-h-3: 74px;
  --i-size-h-4: 78px;
  --i-size-h-5: 70px;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  height: 240px;
  padding-block: 20px;
  padding-inline: 20px;
  font-size: clamp(14px, 1.2vw, 22px);
  position: relative;
}

.trust__item span {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
  display: flex !important;
}

.trust__item span:before {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

.trust__item:first-child {
  color: #fff;
  text-transform: uppercase;
  background-color: #ed7817;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  display: none;
}

.trust__item:first-child:after {
  content: "";
  background-color: #fff;
  width: 12px;
  height: auto;
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: -1px;
}

.trust__item:first-child > span:before {
  content: none;
}

.trust__item:nth-child(2) > span:before {
  width: var(--i-size-w-1);
  height: var(--i-size-h-1);
}

.trust__item:nth-child(3) > span:before {
  width: var(--i-size-w-2);
  height: var(--i-size-h-2);
}

.trust__item:nth-child(4) > span:before {
  width: var(--i-size-w-3);
  height: var(--i-size-h-3);
}

.trust__item:nth-child(5) > span:before {
  width: var(--i-size-w-4);
  height: var(--i-size-h-4);
}

.trust__item:nth-child(6) > span:before {
  width: var(--i-size-w-5);
  height: var(--i-size-h-5);
}

.trust__item:nth-child(2) > span:before {
  background-image: url("trust-1.dfc9aef2.svg");
}

.trust__item:nth-child(3) > span:before {
  background-image: url("trust-2.a096932a.svg");
}

.trust__item:nth-child(4) > span:before {
  background-image: url("trust-3.624e5a20.svg");
}

.trust__item:nth-child(5) > span:before {
  background-image: url("trust-4.96a36588.svg");
}

.trust__item:nth-child(6) > span:before {
  background-image: url("trust-5.d51e2322.svg");
}

.trust__pic {
  grid-area: 3 / 1 / 4 / -1;
  display: block;
}

.trust__pic img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (width <= 1680.98px) {
  .trust__title {
    font-size: 6.25vw;
  }

  .trust__header-subtitle {
    font-size: 1.146vw;
    bottom: 1.4vw;
    left: 44.5vw;
  }
}

@media (width <= 1400.98px) {
  .trust__list {
    gap: 16px;
  }

  .trust__item {
    --i-size-w-1: 63px;
    --i-size-w-2: 54px;
    --i-size-w-3: 33px;
    --i-size-w-4: 55px;
    --i-size-w-5: 50px;
    --i-size-h-1: 45px;
    --i-size-h-2: 38px;
    --i-size-h-3: 46px;
    --i-size-h-4: 50px;
    --i-size-h-5: 50px;
    min-width: 180px;
    height: 150px;
  }

  .trust__pic {
    height: 510px;
  }
}

@media (width <= 1190.98px) {
  .trust__header {
    grid-area: 1 / 1 / 2 / -1;
  }

  .trust__header-subtitle {
    display: none;
  }

  .trust__title {
    font-size: 10vw;
  }

  .trust__list {
    grid-area: 2 / 1 / 3 / -1;
    grid-template-columns: repeat(var(--columns), 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin-block-end: 40px;
  }

  .trust__item {
    padding-block: 16px;
    padding-inline: 16px;
  }

  .trust__item:first-child {
    grid-area: 1 / 2 / 2 / 3;
    padding-inline-start: 22px;
    display: block;
  }

  .trust__item:nth-child(2) {
    grid-area: 1 / 3 / 2 / 4;
  }

  .trust__item:nth-child(3) {
    grid-area: 1 / 4 / 2 / 5;
  }

  .trust__item:nth-child(4) {
    grid-area: 2 / 2 / 3 / 3;
  }

  .trust__item:nth-child(5) {
    grid-area: 2 / 3 / 3 / 4;
  }

  .trust__item:nth-child(6) {
    grid-area: 2 / 4 / 3 / 5;
  }
}

@media (width <= 888.98px) {
  .trust__pic {
    height: 350px;
  }
}

@media (width <= 698.98px) {
  .trust__item {
    min-width: unset;
  }

  .trust__item:first-child {
    grid-area: 1 / 1 / 2 / 2;
  }

  .trust__item:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }

  .trust__item:nth-child(3) {
    grid-area: 2 / 1 / 3 / 2;
  }

  .trust__item:nth-child(4) {
    grid-area: 2 / 2 / 3 / 3;
  }

  .trust__item:nth-child(5) {
    grid-area: 3 / 1 / 4 / 2;
  }

  .trust__item:nth-child(6) {
    grid-area: 3 / 2 / 4 / 3;
  }

  .trust__pic {
    height: 256px;
  }
}

.trust__title > span:first-child {
  padding-left: 5.885vw;
}

.trust__title > span:nth-child(2) {
  padding-left: 11.354vw;
}

@media (width <= 1190.98px) {
  .trust__title > span:first-child, .trust__title > span:nth-child(2) {
    padding-left: 0;
  }

  .trust__title > span:nth-child(3) {
    padding-left: 10.484vw;
  }
}

.force {
  grid-template-columns: repeat(8, 1fr);
  position: relative;
}

.force:before {
  content: "";
  z-index: -1;
  margin-right: calc(var(--p-inline) * -1);
  background-color: #f5f5f5;
  width: 53.646vw;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.force__title {
  grid-area: 1 / 6 / 2 / 9;
  margin-block-end: 48px;
  font-size: clamp(70px, 4.167vw, 80px);
  overflow: hidden;
}

.force__title span:nth-child(2), .force__title span:nth-child(3) {
  font-size: clamp(43px, 2.604vw, 50px);
}

.force__text {
  grid-area: 2 / 6 / 3 / 9;
  margin-block-end: 68px;
  font-size: clamp(14px, 1.2vw, 22px);
  line-height: 1.4;
}

.force__link > * {
  transition: fill .2s;
}

.force__link:hover > * {
  fill: #000;
}

.force__pic-1 {
  grid-area: 3 / 6 / 4 / 9;
}

.force__pic-1 img {
  width: 100%;
}

.force__pic-2 {
  grid-area: 1 / 2 / 4 / 5;
  width: 34.896vw;
  max-width: 670px;
  height: 41.667vw;
  max-height: 800px;
  display: flex;
  position: relative;
}

.force__pic-2 img {
  object-fit: cover;
  object-position: -11vw;
  width: 100%;
  height: 100%;
}

@media (width <= 1680.98px) {
  .force__title {
    font-size: 4.167vw;
  }

  .force__title span:nth-child(2), .force__title span:nth-child(3) {
    font-size: 2.604vw;
  }
}

@media (width <= 1400.98px) {
  .force__text {
    font-size: 14px;
  }

  .force__pic-2 {
    width: 34.833vw;
  }
}

@media (width <= 1190.98px) {
  .force {
    grid-template-columns: repeat(var(--columns), 1fr);
    grid-template-rows: repeat(3, auto);
  }

  .force:before {
    margin-left: calc(var(--p-inline) * -1);
    width: 100vw;
    height: 80%;
  }

  .force__title {
    margin-top: 30px;
    grid-area: 1 / 1 / 2 / 5;
    margin-block-end: 40px;
    font-size: 6.72vw;
  }

  .force__title span:nth-child(2), .force__title span:nth-child(3) {
    font-size: 4.301vw;
  }

  .force__text {
    grid-area: 2 / 1 / 3 / 3;
    margin-block-end: 0;
    padding-right: 11vw;
  }

  .force__pic-1 {
    grid-area: 2 / 3 / 3 / 5;
  }

  .force__pic-2 {
    max-width: unset;
    max-height: unset;
    grid-area: 3 / 1 / 4 / 5;
    width: 100%;
    height: 300px;
    margin-block-start: 40px;
  }

  .force__pic-2 img {
    object-position: 0;
  }
}

@media (width <= 698.98px) {
  .force__title {
    grid-area: 1 / 1 / 2 / 3;
    margin-block-end: 32px;
    font-size: 11.111vw;
  }

  .force__title span:nth-child(2), .force__title span:nth-child(3) {
    font-size: 8.333vw;
  }

  .force__text {
    grid-area: 2 / 1 / 3 / 3;
    margin-block-end: 28px;
    padding-right: 0;
  }

  .force__pic-1 {
    grid-area: 3 / 1 / 4 / 3;
  }

  .force__pic-2 {
    grid-area: 4 / 1 / 5 / 3;
    height: 256px;
  }
}

.force__title span:nth-child(2) {
  translate: 10%;
}

.force__title span:nth-child(3) {
  translate: 20%;
}

@media (width <= 698.98px) {
  .force__title span:nth-child(2), .force__title span:nth-child(3) {
    translate: 20px;
  }
}

.pros {
  grid-template-rows: repeat(4, auto);
  position: relative;
}

.pros:before {
  content: "";
  z-index: -1;
  margin-left: calc(var(--p-inline) * -1);
  background-image: url("bg.5567cecc.svg");
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pros__title {
  grid-area: 1 / 2 / 2 / 7;
  margin-block-end: 60px;
  font-size: 80px;
  position: relative;
  overflow: hidden;
}

.pros__title--mob {
  display: none;
}

.pros__content {
  grid-area: 2 / 2 / 3 / 6;
  translate: 100px;
}

.pros__list {
  grid-template-columns: repeat(2, auto);
  gap: 30px 200px;
  font-size: 22px;
  line-height: 1.4;
  display: grid;
}

.pros__item::marker {
  content: none;
  font-size: 0;
}

.pros__item span {
  padding-left: 40px;
  display: block;
  position: relative;
}

.pros__item span:before {
  content: "";
  background-image: url("li-item.0fc53ca3.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.pros__assets {
  grid-area: 3 / 2 / 4 / -1;
  grid-template-columns: repeat(4, 1fr);
  margin-block-start: 100px;
  display: grid;
}

.pros__pic {
  grid-area: 4 / 1 / 5 / -1;
  margin-block-start: 120px;
}

.pros__pic img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (width <= 1680.98px) {
  .pros__title {
    font-size: 4.167vw;
  }

  .pros__list {
    gap: 30px 5vw;
  }

  .pros__assets {
    grid-area: 3 / 1 / 4 / -1;
  }
}

@media (width <= 1400.98px) {
  .pros__title {
    margin-block-end: 38px;
  }

  .pros__list {
    font-size: 14px;
  }

  .pros__item span {
    padding-inline: 26px 10px;
  }

  .pros__item span:before {
    width: 20px;
    height: 20px;
  }

  .pros__assets {
    margin-block-start: 60px;
  }

  .pros__pic {
    height: 506px;
    margin-block-start: 73px;
  }
}

@media (width <= 1190.98px) {
  .pros__title {
    grid-area: 1 / 1 / 2 / -1;
    font-size: 6.72vw;
  }

  .pros__content {
    grid-area: 2 / 1 / 3 / 5;
    translate: 0;
  }

  .pros__assets {
    grid-area: 3 / 1 / 4 / -1;
    margin-block-start: 40px;
  }

  .pros__pic {
    height: 350px;
  }
}

@media (width <= 698.98px) {
  .pros {
    flex-direction: column;
    display: flex;
  }

  .pros:before {
    content: none;
  }

  .pros .section__title {
    top: 15px;
  }

  .pros__title {
    margin-block-end: 36px;
    font-size: 10vw;
    margin-top: 40px !important;
  }

  .pros__title--desk {
    display: none;
  }

  .pros__title--mob {
    display: block;
  }

  .pros__pic {
    height: 256px;
    margin-block-start: 50px;
    position: relative;
  }

  .pros__list {
    flex-direction: column;
    gap: 20px;
    width: 100%;
    display: flex;
  }

  .pros__assets {
    grid-area: 3 / 1 / 4 / -1;
    grid-template-columns: repeat(2, 1fr);
  }
}

.pros__title span:nth-child(2) {
  translate: 40px;
}

.pros__title span:nth-child(3) {
  font-size: 40px;
  translate: 100px;
}

@media (width <= 1680.98px) {
  .pros__title span:nth-child(2) {
    translate: 2.083vw;
  }

  .pros__title span:nth-child(3) {
    font-size: 2.083vw;
    translate: 5.208vw;
  }
}

@media (width <= 1190.98px) {
  .pros__title span:nth-child(2) {
    translate: 3.083vw;
  }

  .pros__title span:nth-child(3) {
    font-size: 3.36vw;
    translate: 3.6vw;
  }
}

@media (width <= 698.98px) {
  .pros__title span:nth-child(2) {
    font-size: 6.667vw;
    translate: 6vw;
  }

  .pros__title span:nth-child(3) {
    font-size: 6.667vw;
    translate: 12vw;
  }

  .pros__title span:nth-child(4), .pros__title span:nth-child(5) {
    font-size: 6.667vw;
    translate: 20vw;
  }
}

.pros .assets {
  background-color: #f5f5f5;
  position: relative;
}

.pros .assets__item {
  margin-block-end: 0;
  padding-block: 50px;
  padding-inline: 10%;
  font-size: clamp(14px, 1.5vw, 22px);
  line-height: 1.3;
  position: static;
}

.pros .assets__item:first-child {
  color: #fff;
  text-transform: uppercase;
  background-color: #fab72d;
  padding-inline: 46px 10%;
  font-size: clamp(14px, 1.3vw, 25px);
  font-weight: 600;
  line-height: 1.2;
}

.pros .assets__item:first-child:before {
  content: "";
  z-index: 1;
  background-color: #fff;
  width: 20px;
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: -1px;
}

.pros .assets__item:last-child {
  padding-inline-end: 46px;
}

.pros .assets__item:last-child:before {
  content: "";
  z-index: 1;
  background-color: #fff;
  width: 20px;
  position: absolute;
  top: 20px;
  bottom: 20px;
  right: -1px;
}

.pros .assets__item span {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.pros .assets__link:after {
  width: 1em;
  height: 1em;
}

@media (width <= 888.98px) {
  .pros .assets__item {
    padding-block: 30px;
    padding-inline: 8%;
  }

  .pros .assets__item:first-child {
    padding-block: 30px;
    padding-inline: 28px 8%;
  }

  .pros .assets__item:first-child:before {
    width: 10px;
    top: 12px;
    bottom: 12px;
  }

  .pros .assets__item:last-child {
    padding-inline-end: 28px;
  }

  .pros .assets__item:last-child:before {
    width: 10px;
    top: 12px;
    bottom: 12px;
  }
}

@media (width <= 698.98px) {
  .pros .assets__item {
    min-height: 135px;
    padding-block: 24px;
    padding-inline: 12px;
  }

  .pros .assets__item:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
    padding-inline-end: 20px;
  }

  .pros .assets__item:nth-child(3) {
    grid-area: 2 / 1 / 3 / 2;
    padding-inline-start: 20px;
  }

  .pros .assets__item:nth-child(4) {
    grid-area: 2 / 2 / 3 / 3;
    padding-inline-end: 20px;
  }
}

.accordion:has(.accordion__tabs).acc--no-tabs .accordion__button {
  color: #000;
  border-bottom: 1px solid #b6b6b6;
  display: flex;
}

.accordion:has(.accordion__tabs).acc--no-tabs .accordion__button-close {
  display: block;
}

.accordion:has(.accordion__tabs).acc--no-tabs .accordion__tabs {
  display: none;
}

.accordion:has(.accordion__tabs).acc--no-tabs .accordion__sections {
  gap: 1px;
}

.accordion:has(.accordion__tabs).acc--no-tabs .accordion__article {
  height: 0;
  transition: height .3s ease-in-out;
  display: block;
  overflow: clip;
}

.accordion:has(.accordion__tabs) .is-expand .accordion__button {
  color: #ed7817;
}

@media screen and (width >= 890px) {
  .accordion:has(.accordion__tabs) .accordion__button, .accordion:has(.accordion__tabs) .accordion__button-close {
    display: none;
  }

  .accordion:has(.accordion__tabs) .accordion__sections {
    gap: 0;
  }

  .accordion:has(.accordion__tabs) .accordion__section {
    margin: 0;
  }

  .accordion:has(.accordion__tabs) .accordion__article {
    overflow: unset;
    height: auto;
    transition: none;
    display: none;
  }
}

@media screen and (width <= 889px) {
  .accordion:has(.accordion__tabs) .accordion__button {
    color: #000;
    border-bottom: 1px solid #b6b6b6;
    display: flex;
  }

  .accordion:has(.accordion__tabs) .accordion__button-close {
    display: block;
  }

  .accordion:has(.accordion__tabs) .accordion__tabs {
    display: none;
  }

  .accordion:has(.accordion__tabs) .accordion__sections {
    gap: 1px;
  }

  .accordion:has(.accordion__tabs) .accordion__article {
    height: 0;
    transition: height .3s ease-in-out;
    display: block;
    overflow: clip;
  }
}

.accordion:not(:has(.accordion__tabs)) .accordion__button {
  color: #000;
  border-bottom: 1px solid #b6b6b6;
  display: flex;
}

.accordion:not(:has(.accordion__tabs)) .accordion__button-close {
  display: block;
}

.accordion:not(:has(.accordion__tabs)) .accordion__tabs {
  display: none;
}

.accordion:not(:has(.accordion__tabs)) .accordion__sections {
  gap: 1px;
}

.accordion:not(:has(.accordion__tabs)) .accordion__article {
  height: 0;
  transition: height .3s ease-in-out;
  display: block;
  overflow: clip;
}

.accordion__sections {
  flex-direction: column;
  gap: 1px;
  display: flex;
}

.accordion__button {
  cursor: pointer;
  text-align: start;
  color: #969696;
  text-align: start;
  background-color: #0000;
  border-bottom: 3px solid;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding-block: 10px;
  padding-inline: 20px 40px;
  transition-property: color, background-color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: relative;
}

.accordion__icon {
  width: 18px;
  height: 18px;
  transition: all .2s ease-in-out;
  position: relative;
}

.accordion__icon:before, .accordion__icon:after {
  content: "";
  background-color: #b6b6b6;
  transition: all .2s ease-in-out;
  position: absolute;
}

.accordion__icon:before {
  width: 100%;
  height: 1.5px;
  top: 50%;
  left: 0;
  translate: 0 -50%;
}

.accordion__icon:after {
  width: 1.5px;
  height: 100%;
  top: 0;
  left: 50%;
  translate: -50%;
}

.accordion__title-wrap {
  align-items: center;
  gap: 15px;
  display: flex;
}

.accordion__title-img {
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: 0;
}

.accordion__title {
  flex-grow: 1;
  margin: 0;
  font-size: 20px;
}

.accordion__article {
  color: #000;
  background-color: #0000;
  margin: 0;
  padding: 0;
  position: relative;
}

.accordion__inner {
  padding-block: 30px;
  padding-inline: 10%;
}

.accordion__tabs {
  justify-content: space-between;
  display: flex;
}

.accordion__tab {
  flex-grow: 1;
  display: block;
}

.accordion__tab-button {
  color: #969696;
  text-align: start;
  background-color: #0000;
  border-bottom: 3px solid;
  width: 100%;
  margin: 0;
  padding-block: 16px;
  padding-inline: 10px;
}

.accordion__tabs-text {
  font-size: 20px;
}

@media (hover: hover) {
  .accordion__section:not(.is-expand) .accordion__button:hover, .accordion__tab:not(.is-expand) .accordion__tab-button:hover {
    color: #ed7817;
    background-color: #0000;
  }

  .accordion__section:not(.is-expand) .accordion__button:hover .accordion__icon, .accordion__tab:not(.is-expand) .accordion__tab-button:hover .accordion__icon {
    color: #ed7817;
  }
}

.accordion .is-expand .accordion__button, .accordion .is-expand .accordion__tab-button {
  color: #ed7817;
  background-color: #0000;
}

.accordion .is-expand .accordion__icon {
  transform: rotate(45deg);
}

.accordion .is-expand .accordion__icon:before, .accordion .is-expand .accordion__icon:after {
  background-color: #ed7817;
}

@media (width <= 1400.98px) {
  .accordion__inner {
    padding-inline: 5%;
  }
}

@media (width <= 1190.98px) {
  .accordion__inner {
    padding-inline: 0;
  }
}

@media (width <= 888.98px) {
  .accordion__inner {
    padding-block: 20px;
  }
}

.choice__title {
  grid-area: 1 / 2 / 2 / -1;
  margin-block-end: 60px;
}

.choice__accordion {
  grid-area: 2 / 2 / 3 / -1;
}

.choice__pic {
  justify-content: center;
  height: 450px;
  margin-block-end: 50px;
  display: flex;
}

.choice__pic img {
  object-fit: contain;
  height: 100%;
}

.choice__text {
  justify-content: space-between;
  display: flex;
}

.choice__logo {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.choice__logo img {
  width: 211px;
}

.choice__status {
  color: #2d2f33;
  background-color: #f5f5f5;
  width: fit-content;
  padding: 8px;
  font-size: 20px;
  line-height: 1.4;
}

.choice__info {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.choice__info p {
  margin-block-end: 0;
}

.choice__place, .choice__time-text, .choice__time-year, .choice__stat-text {
  font-size: 22px;
}

.choice__project {
  font-size: clamp(24px, 1.2vw, 34px);
  font-weight: 600;
}

.choice__place {
  font-size: 22px;
}

.choice__time, .choice__stat {
  justify-content: space-between;
  align-items: baseline;
  display: flex;
}

.choice__stat-text {
  margin-inline-end: 40px;
}

.choice__stat-num {
  color: #ed7817;
  flex-shrink: 0;
  font-size: clamp(24px, 1.2vw, 32px);
  font-weight: 600;
}

@media (width <= 1400.98px) {
  .choice__pic {
    height: 350px;
  }
}

@media (width <= 1190.98px) {
  .choice__title {
    grid-area: 1 / 1 / 2 / -1;
    margin-block-end: 45px;
  }

  .choice__accordion {
    grid-area: 2 / 1 / 3 / -1;
  }
}

@media (width <= 888.98px) {
  .choice__title {
    margin-block-end: 30px;
  }

  .choice__pic {
    height: 250px;
  }

  .choice__text {
    flex-direction: column;
    gap: 30px;
  }

  .choice__logo {
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }

  .choice__place, .choice__time-text, .choice__time-year, .choice__stat-text {
    font-size: 18px;
  }

  .choice__stat-text {
    margin-inline-end: 20px;
  }
}

@media (width <= 698.98px) {
  .choice__logo img {
    width: 140px;
  }
}

@media (width <= 398.98px) {
  .choice__logo img {
    width: 130px;
  }

  .choice__status {
    font-size: 12px;
  }

  .choice__pic {
    height: 220px;
    margin-block-end: 30px;
  }
}

.more {
  background-color: #f5f5f5;
}

.more:before {
  content: "";
  z-index: -1;
  margin-left: calc(var(--p-inline) * -1);
  background-color: #f5f5f5;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.more__title {
  grid-area: 1 / 2 / 2 / 5;
  margin-block-end: 80px;
}

.more__balmax {
  grid-area: 2 / 2 / 3 / 4;
}

.more__balmax .pic {
  padding-block-start: 26px;
  position: relative;
}

.more__balmax .pic__decor {
  background-color: #fab72d;
  width: 45%;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
}

.more__balmax .pic, .more__balmax .more__info {
  overflow: hidden;
}

.more__balmax .pic > *, .more__balmax .more__info > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: -100%;
}

.more__balmax .pic.anim > *, .more__balmax .more__info.anim > * {
  opacity: 1;
  translate: 0;
}

.more__evraz {
  grid-area: 2 / -3 / 3 / -1;
}

.more__evraz .pic, .more__evraz .more__info {
  overflow: hidden;
}

.more__evraz .pic > *, .more__evraz .more__info > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: 100%;
}

.more__evraz .pic.anim > *, .more__evraz .more__info.anim > * {
  opacity: 1;
  translate: 0;
}

.more__item {
  flex-direction: column;
  gap: 25px;
  display: flex;
}

.more__info {
  flex-direction: column;
  gap: 7px;
  display: flex;
  position: relative;
}

.more__link {
  font-size: clamp(16px, 2vw, 25px);
}

.more__logo {
  width: 200px;
}

.more__logo img {
  height: 100%;
}

.more .pic {
  position: relative;
}

@media (width <= 1190.98px) {
  .more__title {
    grid-area: 1 / 1 / 2 / 3;
  }

  .more__balmax {
    grid-area: 2 / 1 / 3 / 3;
    padding-inline-end: 50px;
  }

  .more__balmax .pic {
    padding-block-start: 15px;
  }

  .more__balmax .pic__decor {
    height: 15px;
  }

  .more__evraz {
    grid-area: 2 / 3 / 3 / 5;
    padding-block-start: 16px;
  }

  .more__logo {
    height: 20px;
  }
}

@media (width <= 698.98px) {
  .more {
    flex-direction: column;
    gap: 60px;
    display: flex;
  }

  .more__title {
    margin-block-end: 14px;
  }

  .more__balmax {
    padding-inline-end: 0;
  }

  .more__evraz {
    padding-block-start: 0;
  }

  .more__evraz .pic {
    order: -1;
  }

  .more .pic {
    overflow: hidden;
  }

  .more .pic > * {
    opacity: 0;
    transition: translate 1s ease-in-out, opacity 1s ease-in-out;
    display: block;
    translate: -100%;
  }

  .more .pic.anim > * {
    opacity: 1;
    translate: 0;
  }

  .more .pic img {
    width: 100%;
  }

  .more .more__info {
    overflow: hidden;
  }

  .more .more__info > * {
    opacity: 0;
    transition: translate 1s ease-in-out, opacity 1s ease-in-out;
    display: block;
    translate: -100%;
  }

  .more .more__info.anim > * {
    opacity: 1;
    translate: 0;
  }

  .more__logo {
    height: 20px;
  }

  .more .section__title {
    top: 15px;
  }
}

.contacts {
  --pt: 80px;
  padding-block-end: 0;
  display: grid;
}

.contacts .section__title {
  top: var(--pt);
}

.contacts__form {
  padding-top: var(--pt);
  grid-area: 1 / 2 / -1 / 4;
  position: relative;
}

.contacts__pic {
  grid-area: 1 / -3 / -1 / -1;
}

.contacts__pic img {
  object-fit: cover;
  height: 100%;
}

.contacts__logo {
  grid-area: 1 / -4 / -1 / -1;
  max-width: 922px;
  margin-top: auto;
  position: relative;
  translate: -5vw;
}

.contacts__logo img {
  width: 100%;
}

.contacts__logo--mob {
  display: none !important;
}

@media (width <= 1190.98px) {
  .contacts .section__title {
    top: 15px;
  }

  .contacts__form {
    grid-area: 1 / 1 / -1 / 3;
    padding-block: 80px 140px;
    padding-inline: 0 30px;
  }

  .contacts__pic {
    grid-area: 1 / 3 / -1 / 5;
  }

  .contacts__logo {
    grid-area: 1 / 2 / -1 / 5;
    translate: -7.65vw;
  }
}

@media (width <= 888.98px) {
  .contacts__logo {
    translate: -7.85vw;
  }
}

@media (width <= 698.98px) {
  .contacts {
    flex-direction: column;
    padding-block-end: 0;
    display: flex;
  }

  .contacts .section__title {
    top: 65px;
  }

  .contacts__form {
    padding-block: 40px;
    padding-inline: 0;
  }

  .contacts__logo {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    translate: 0 2px;
  }

  .contacts__pic {
    height: 367px;
  }

  .contacts__pic img {
    width: 100%;
  }

  .contacts__logo--desk {
    display: none !important;
  }

  .contacts__logo--mob {
    display: block !important;
  }
}

.form__fieldset {
  border: none;
  margin: 0;
  padding: 0 1px;
}

.form__legend {
  color: #2d2f33;
  text-transform: uppercase;
  margin-block-end: 40px;
  font-size: clamp(28px, 2.3vw, 40px);
}

.form__text {
  color: #2d2f33;
  margin-block-end: 50px;
  line-height: 1.5;
  font-size: clamp(16px, 1.6vw, 25px) !important;
}

.form__text a {
  color: #fff;
  background-color: #ed7817;
  margin-inline-start: 5px;
  padding: 1px 8px;
  padding-inline-start: 38px;
  display: inline-block;
  position: relative;
}

.form__text a:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns=\"http://www.w3.org/2000/svg\" width=\"25\" height=\"25\" fill=\"none\"%3E%3Cpath fill=\"%23fff\" fill-rule=\"evenodd\" d=\"M13.217 2.876a.75.75 0 0 0-.832 0l-8.99 5.993a.745.745 0 0 0-.344.63v9.751a1.5 1.5 0 0 0 1.5 1.5h16.5a1.5 1.5 0 0 0 1.5-1.5V9.513a.747.747 0 0 0-.348-.646l-8.986-5.991Zm7.263 6.645L12.8 4.4l-7.678 5.12L11.405 14h2.793l6.282-4.48ZM4.55 10.956v7.548l5.32-3.757-5.32-3.791Zm6.854 4.544-5.309 3.75h13.413L14.2 15.5h-2.795Zm4.33-.753 5.317 3.756v-7.547l-5.317 3.791Z\" clip-rule=\"evenodd\"/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 8px;
  translate: 0 -50%;
}

@media (hover: hover) {
  .form__text a:hover {
    text-decoration: underline;
  }
}

.form__group {
  margin-block-end: 10px;
}

.form__input, .form__textarea {
  background-color: #fff;
  border: none;
  outline: none;
  width: 100%;
  padding-block: 18px;
  padding-inline: 23px;
  box-shadow: 0 6.4px 64px #0000000d;
}

.form__textarea {
  resize: none;
}

.form__button {
  color: #fff;
  background-color: #ed7817;
  border: none;
  justify-content: center;
  align-items: center;
  padding-block: 8px;
  padding-inline: 7%;
  font-size: clamp(16px, 1.6vw, 25px);
  line-height: 1;
  display: flex;
}

@media (hover: hover) {
  .form__button:hover {
    color: #fff;
    background-color: #d66f15;
  }
}

@media (width <= 1190.98px) {
  .form__legend {
    margin-block-end: 26px;
  }

  .form__text {
    margin-block-end: 36px;
  }
}

@media (width <= 698.98px) {
  .form__button {
    padding-block: 8px;
    padding-inline: 25px;
    font-size: 18px;
  }
}

.checkbox {
  margin-block: 15px 20px;
}

.checkbox__label {
  cursor: pointer;
  width: 100%;
  padding-inline-start: 36px;
  display: block;
  position: relative;
}

@media (hover: hover) {
  .checkbox__label:hover:not(:has(a:hover)) .checkbox__box {
    background-color: #ed7817;
  }
}

.checkbox__box {
  top: 50%;
  border: 1px solid #ed7817;
  width: 25px;
  height: 25px;
  position: absolute;
  inset-inline-start: 0;
  translate: 0 -50%;
}

.checkbox__box:before {
  content: "";
  background-image: url("checkmark.60d8e3ff.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}

.checkbox__input:checked + .checkbox__box {
  background-color: #ed7817;
}

.checkbox__input:checked + .checkbox__box:before {
  display: block;
}

.checkbox__text {
  font-size: clamp(14px, 1.4vw, 20px);
  display: block;
}

.checkbox__text a {
  color: #ed7817;
}

@media (hover: hover) {
  .checkbox__text a:hover {
    text-decoration: underline;
  }
}

@media (width <= 698.98px) {
  .checkbox__label {
    padding-inline-start: 30px;
  }

  .checkbox__box {
    width: 22px;
    height: 22px;
  }
}

.footer {
  grid-template-columns: repeat(var(--columns), 1fr);
  border-top: 1px solid #dedede;
  padding-block: 26px;
  display: grid;
}

.footer__copyright {
  grid-area: 1 / 2 / 2 / 3;
}

.footer__social {
  grid-area: 1 / 4 / 2 / 5;
}

.footer__contacts {
  text-align: end;
  flex-direction: column;
  grid-area: 1 / -2 / 2 / -1;
  gap: 3px;
  display: flex;
}

.footer__contacts p, .footer__contacts a {
  color: #2d2f33;
  margin: 0;
  line-height: 1;
}

@media (hover: hover) {
  .footer__contacts a:hover {
    text-decoration: underline;
  }
}

.footer address, .footer p, .footer a {
  color: #969696;
  font-size: 1rem;
  font-style: normal;
}

@media (width <= 1190.98px) {
  .footer__copyright {
    grid-area: 1 / 1 / 2 / 2;
  }

  .footer__social {
    grid-area: 1 / 3 / 2 / 4;
  }

  .footer__contacts {
    grid-area: 1 / 4 / 2 / -1;
  }
}

@media (width <= 698.98px) {
  .footer {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
  }

  .footer address, .footer p, .footer a {
    font-size: 14px;
  }

  .footer__social {
    justify-content: end;
    width: 40%;
    display: flex;
  }

  .footer__contacts {
    text-align: start;
    order: -1;
    gap: 12px;
    width: 60%;
  }

  .footer__copyright {
    text-align: start;
    text-wrap: balance;
    order: 1;
    width: 100%;
    margin-top: 10px;
    margin-right: auto;
  }
}

.social__list {
  align-items: center;
  gap: 28px;
  display: flex;
}

.social__item {
  display: flex;
}

.social__link {
  width: 36px;
  height: 36px;
}

@media (hover: hover) {
  .social__link:hover svg path {
    fill: #ed7817;
  }
}

@media (width <= 1400.98px) {
  .social__link {
    width: 22px;
    height: 22px;
  }
}

@media (width <= 1190.98px) {
  .social__list {
    align-items: flex-start;
    gap: 16px;
  }
}

.header {
  transition: all .8s cubic-bezier(.68, -.55, .27, 1.55);
  translate: 0 -100px;
}

.header.anim {
  translate: 0;
}

.front__pic img {
  transition: all 1.1s ease-in-out;
  scale: 1.2;
}

.front__pic.anim img {
  scale: 1;
}

.title > span {
  overflow: hidden;
}

.title > span span {
  opacity: 0;
  transition: all 1.1s ease-in-out;
  translate: 0 100%;
}

.title.anim > span span {
  opacity: 1;
  translate: 0;
}

.section__title {
  opacity: 0;
  transition: all 1.1s ease-in-out;
  translate: -80%;
}

.section__title.anim {
  opacity: 1;
  translate: 0;
}

.assets__item {
  overflow: hidden;
}

.assets__item > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: -100%;
}

.assets__item.anim > * {
  opacity: 1;
  translate: 0;
}

@media (width <= 698.98px) {
  .assets__item:nth-child(2n) {
    overflow: hidden;
  }

  .assets__item:nth-child(2n) > * {
    opacity: 0;
    transition: translate 1s ease-in-out, opacity 1s ease-in-out;
    display: block;
    translate: 100%;
  }

  .assets__item:nth-child(2n).anim > * {
    opacity: 1;
    translate: 0;
  }
}

.trust__item {
  overflow: hidden;
}

.trust__item > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: -100%;
}

.trust__item.anim > * {
  opacity: 1;
  translate: 0;
}

@media (width <= 698.98px) {
  .trust__item:nth-child(2n) {
    overflow: hidden;
  }

  .trust__item:nth-child(2n) > * {
    opacity: 0;
    transition: translate 1s ease-in-out, opacity 1s ease-in-out;
    display: block;
    translate: 100%;
  }

  .trust__item:nth-child(2n).anim > * {
    opacity: 1;
    translate: 0;
  }
}

.pros__item {
  overflow: hidden;
}

.pros__item > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: -100%;
}

.pros__item.anim > * {
  opacity: 1;
  translate: 0;
}

.pros__item:nth-child(2n) {
  overflow: hidden;
}

.pros__item:nth-child(2n) > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: 100%;
}

.pros__item:nth-child(2n).anim > * {
  opacity: 1;
  translate: 0;
}

@media (width <= 698.98px) {
  .pros__item:nth-child(2n) {
    overflow: hidden;
  }

  .pros__item:nth-child(2n) > * {
    opacity: 0;
    transition: translate 1s ease-in-out, opacity 1s ease-in-out;
    display: block;
    translate: -100%;
  }

  .pros__item:nth-child(2n).anim > * {
    opacity: 1;
    translate: 0;
  }
}

.pros .title .pros__title--desk > span, .pros .title .pros__title--mob > span {
  overflow: hidden;
}

.pros .title .pros__title--desk > span span, .pros .title .pros__title--mob > span span {
  opacity: 0;
  transition: all 1.1s ease-in-out;
  translate: 0 100%;
}

.pros .title .pros__title--desk.anim > span span, .pros .title .pros__title--mob.anim > span span {
  opacity: 1;
  translate: 0;
}

.pic__wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pic__img {
  transition: all 1.1s ease-in-out .3s;
  translate: 5% -5%;
  scale: 1.1;
}

.pic__img--small {
  translate: 15% -15%;
}

.pic.anim .pic__img {
  translate: 0;
  scale: 1;
}

.form__item:first-child:not(.anim-end) {
  transition-delay: 0s !important;
}

.form__item:nth-child(2):not(.anim-end) {
  transition-delay: .1s !important;
}

.form__item:nth-child(3):not(.anim-end) {
  transition-delay: .2s !important;
}

.form__item:nth-child(4):not(.anim-end) {
  transition-delay: .3s !important;
}

.form__item:nth-child(5):not(.anim-end) {
  transition-delay: .4s !important;
}

.form__item:nth-child(6):not(.anim-end) {
  transition-delay: .5s !important;
}

.form__item:nth-child(7):not(.anim-end) {
  transition-delay: .6s !important;
}

.animLeft {
  overflow: hidden;
}

.animLeft > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: -100%;
}

.animLeft.anim > * {
  opacity: 1;
  translate: 0;
}

.animRight {
  overflow: hidden;
}

.animRight > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: 100%;
}

.animRight.anim > * {
  opacity: 1;
  translate: 0;
}

.animBottom {
  overflow: hidden;
}

.animBottom > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: 0 100%;
}

.animBottom.anim > * {
  opacity: 1;
  translate: 0;
}
/*# sourceMappingURL=balmax.50b55e70.css.map */
