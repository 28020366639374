.more {
  background-color: $color-gray-1;

  &::before {
    content: '';

    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100%;
    margin-left: calc(var(--p-inline) * -1);

    background-color: $color-gray-1;
  }

  &__title {
    grid-area: 1 / 2 / 2 / 5;
    margin-block-end: 80px;
  }

  &__balmax {
    grid-area: 2 / 2 / 3 / 4;

    .pic {
      position: relative;
      padding-block-start: 26px;
    }

    .pic__decor {
      position: absolute;
      top: 0;
      left: 0;

      width: 45%;
      height: 26px;

      background-color: $color-yellow;
    }

    .pic,
    .more__info {
      @include animLeft;
    }
  }

  &__evraz {
    grid-area: 2 / -3 / 3 / -1;

    .pic,
    .more__info {
      @include animRight;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  &__info {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  &__link {
    font-size: clamp(16px, 2vw, 25px);
  }

  &__logo {
    width: 200px;

    img {
      height: 100%;
    }
  }

  .pic {
    position: relative;
  }
  @include tzar-breakpoint-down(lg) {
    &__title {
      grid-area: 1 / 1 / 2 / 3;
    }

    &__balmax {
      grid-area: 2 / 1 / 3 / 3;
      padding-inline-end: 50px;

      .pic {
        padding-block-start: 15px;
      }

      .pic__decor {
        height: 15px;
      }
    }

    &__evraz {
      grid-area: 2 / 3 / 3 / 5;
      padding-block-start: 16px;
    }

    &__logo {
      height: 20px;
    }
  }
  @include tzar-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    gap: 60px;

    &__title {
      margin-block-end: 14px;
    }

    &__balmax {
      padding-inline-end: 0;
    }

    &__evraz {
      padding-block-start: 0;

      .pic {
        order: -1;
      }
    }

    .pic {
      @include animLeft;

      img {
        width: 100%;
      }
    }

    .more__info {
      @include animLeft;
    }

    &__logo {
      height: 20px;
    }

    .section__title {
      top: 15px;
    }
  }
}
