.trust {
  position: relative;

  &::before {
    content: '';

    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100%;
    margin-left: calc(var(--p-inline) * -1);

    background-image: url('../images/icons/balmax/bg.svg');
  }

  &__header {
    position: relative;
    grid-area: 1 / 2 / 2 / -1;
    margin-block-end: 50px;
    letter-spacing: 0.02em;
  }

  &__header-subtitle {
    position: absolute;
    bottom: 30px;
    left: 860px;
  }

  &__header-subtitle span {
    display: block;

    padding-block: 0.781vw 0.625vw;
    padding-inline: 1.25vw 2.188vw;

    font-size: 22px;
    font-weight: 500;
    line-height: 1.2;
    color: white;
    text-transform: uppercase;

    background-color: $color-orange;

    &::before {
      content: '';

      position: absolute;
      top: 10px;
      right: -1px;
      bottom: 10px;

      width: 0.729vw;

      background-color: white;
    }
  }

  &__title {
    overflow: hidden;
    font-size: clamp(75px, 6.25vw, 120px);
  }

  &__list {
    display: grid;
    grid-area: 2 / 1 / 3 / -1;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;

    margin-block-end: 6.771vw;
  }

  &__item {
    --i-size-w-1: 100px;
    --i-size-w-2: 85px;
    --i-size-w-3: 51px;
    --i-size-w-4: 87px;
    --i-size-w-5: 70px;
    --i-size-h-1: 73px;
    --i-size-h-2: 59px;
    --i-size-h-3: 74px;
    --i-size-h-4: 78px;
    --i-size-h-5: 70px;

    position: relative;

    height: 240px;
    padding-block: 20px;
    padding-inline: 20px;

    font-size: clamp(14px, 1.2vw, 22px);

    background-color: white;
    border: 1px solid #d1d1d1;

    span {
      position: relative;

      display: flex !important;
      flex-direction: column;
      justify-content: space-between;

      height: 100%;

      &::before {
        content: '';

        display: block;

        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    &:first-child {
      display: none;

      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
      color: white;
      text-transform: uppercase;

      background-color: $color-orange;
      border: none;

      &::after {
        content: '';

        position: absolute;
        top: 10px;
        bottom: 10px;
        left: -1px;

        width: 12px;
        height: auto;

        background-color: white;
      }

      & > span::before {
        content: none;
      }
    }

    &:nth-child(2) > span::before {
      width: var(--i-size-w-1);
      height: var(--i-size-h-1);
    }

    &:nth-child(3) > span::before {
      width: var(--i-size-w-2);
      height: var(--i-size-h-2);
    }

    &:nth-child(4) > span::before {
      width: var(--i-size-w-3);
      height: var(--i-size-h-3);
    }

    &:nth-child(5) > span::before {
      width: var(--i-size-w-4);
      height: var(--i-size-h-4);
    }

    &:nth-child(6) > span::before {
      width: var(--i-size-w-5);
      height: var(--i-size-h-5);
    }
    @for $i from 2 through 6 {
      &:nth-child(#{$i}) > span::before {
        background-image: url('../images/icons/balmax/trust-#{$i - 1}.svg');
      }
    }
  }

  &__pic {
    display: block;
    grid-area: 3 / 1 / 4 / -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @include tzar-breakpoint-down(xxl) {
    &__title {
      font-size: 6.25vw;
    }

    &__header-subtitle {
      bottom: 1.4vw;
      left: 44.5vw;
      font-size: 1.146vw;
    }
  }
  @include tzar-breakpoint-down(xl) {
    &__list {
      gap: 16px;
    }

    &__item {
      --i-size-w-1: 63px;
      --i-size-w-2: 54px;
      --i-size-w-3: 33px;
      --i-size-w-4: 55px;
      --i-size-w-5: 50px;
      --i-size-h-1: 45px;
      --i-size-h-2: 38px;
      --i-size-h-3: 46px;
      --i-size-h-4: 50px;
      --i-size-h-5: 50px;

      min-width: 180px;
      height: 150px;
    }

    &__pic {
      height: 510px;
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__header {
      grid-area: 1 / 1 / 2 / -1;
    }

    &__header-subtitle {
      display: none;
    }

    &__title {
      font-size: 10vw;
    }

    &__list {
      grid-area: 2 / 1 / 3 / -1;
      grid-template-columns: repeat(var(--columns), 1fr);
      grid-template-rows: repeat(2, 1fr);
      margin-block-end: 40px;
    }

    &__item {
      padding-block: 16px;
      padding-inline: 16px;

      &:first-child {
        display: block;
        grid-area: 1 / 2 / 2 / 3;
        padding-inline-start: 22px;
      }

      &:nth-child(2) {
        grid-area: 1 / 3 / 2 / 4;
      }

      &:nth-child(3) {
        grid-area: 1 / 4 / 2 / 5;
      }

      &:nth-child(4) {
        grid-area: 2 / 2 / 3 / 3;
      }

      &:nth-child(5) {
        grid-area: 2 / 3 / 3 / 4;
      }

      &:nth-child(6) {
        grid-area: 2 / 4 / 3 / 5;
      }
    }
  }
  @include tzar-breakpoint-down(md) {
    &__pic {
      height: 350px;
    }
  }
  @include tzar-breakpoint-down(sm) {
    &__item {
      min-width: unset;

      &:first-child {
        grid-area: 1 / 1 / 2 / 2;
      }

      &:nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;
      }

      &:nth-child(3) {
        grid-area: 2 / 1 / 3 / 2;
      }

      &:nth-child(4) {
        grid-area: 2 / 2 / 3 / 3;
      }

      &:nth-child(5) {
        grid-area: 3 / 1 / 4 / 2;
      }

      &:nth-child(6) {
        grid-area: 3 / 2 / 4 / 3;
      }
    }

    &__pic {
      height: 256px;
    }
  }
}

.trust__title > span {
  &:nth-child(1) {
    padding-left: 5.885vw;
  }

  &:nth-child(2) {
    padding-left: 11.354vw;
  }
  @include tzar-breakpoint-down(lg) {
    &:nth-child(1),
    &:nth-child(2) {
      padding-left: 0;
    }

    &:nth-child(3) {
      padding-left: 10.484vw;
    }
  }
}
