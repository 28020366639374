.tools {
  background-color: $color-gray-1;

  &::before {
    content: '';

    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100%;
    margin-left: calc(var(--p-inline) * -1);

    background-color: $color-gray-1;
  }

  &__title {
    grid-area: 1 / 2 / 2 / 6;
    margin-block-end: 42px;
    font-size: 120px;
  }

  &__balmax {
    position: relative;
    display: grid;
    grid-area: 2 / 2 / 3 / -1;
    grid-template-columns: repeat(5, 1fr);
  }
  @include tzar-breakpoint-down(xxl) {
    &__title {
      font-size: 6.25vw;
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__title {
      grid-area: 1 / 1 / 2 / 5;
      font-size: 10vw;
    }

    &__balmax {
      grid-area: 2 / 1 / 3 / -1;
      grid-template-columns: repeat(4, 1fr);
    }

    &__assets {
      grid-area: 3 / 1 / 4 / -1;
    }
  }
  @include tzar-breakpoint-down(sm) {
    &__balmax {
      grid-area: 2 / 1 / 3 / -1;
    }

    &__assets {
      grid-area: 3 / 1 / 4 / -1;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @include tzar-breakpoint-down(xs) {
    &__title {
      font-size: 11.111vw;
    }
  }
}

.tools-balmax {
  &__stat {
    display: grid;
    grid-area: 1 / 1 / 2 / 3;
    grid-template-columns: repeat(1, 1fr);
  }

  &__logo {
    width: 50%;
    margin-block-end: 10px;

    img {
      width: 100%;
    }
  }

  &__value {
    margin-block-end: 38px;
    font-weight: 700;
  }

  &__pic {
    position: absolute;
    right: calc(var(--p-inline) * -1);

    grid-area: 1 / 4 / 2 / 6;

    width: 33.854vw;
    max-width: 656px;
    margin-top: -500px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    margin-block-end: 0;
    padding-right: 5%;
    font-size: 20px;
    line-height: 1.3;
  }
  @include tzar-breakpoint-down(xxl) {
    &__pic {
      margin-top: -27.381vw;
    }

    p {
      font-size: 16px;
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__pic {
      position: relative;
      width: auto;
      margin-top: 0;
    }
  }
  @include tzar-breakpoint-down(md) {
    &__pic {
      grid-area: 1 / 3 / 2 / 5;
    }
  }
  @include tzar-breakpoint-down(sm) {
    &__stat {
      grid-area: 1 / 1 / 2 / 5;
    }

    &__pic {
      grid-area: 2 / 2 / 3 / 5;
    }
  }
}

.tools .assets {
  position: relative;

  display: grid;
  grid-area: 3 / 1 / 4 / -1;
  grid-template-columns: repeat(4, 1fr);

  margin-block-start: 44px;

  background-color: white;

  &__item {
    --i-size: 58px;

    height: 255px;
    margin-block-end: 0;
    padding-block: 30px;
    padding-inline: 10%;

    font-size: clamp(14px, 1.5vw, 22px);
    line-height: 1.3;

    &:first-child {
      padding-inline-start: 46px;

      &::after {
        content: '';

        position: absolute;
        top: 20px;
        bottom: 20px;
        left: -1px;

        width: 20px;

        background-color: $color-gray-1;
      }
    }

    &:last-child {
      padding-inline-end: 46px;

      &::after {
        content: '';

        position: absolute;
        top: 20px;
        right: -1px;
        bottom: 20px;

        width: 20px;

        background-color: $color-gray-1;
      }
    }
    @for $i from 1 through 4 {
      &:nth-child(#{$i}) span::before {
        background-image: url('../images/icons/balmax/tools-#{$i}.svg');
      }
    }
  }

  &__item span {
    position: static;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;

    &::before {
      content: '';

      display: block;

      width: var(--i-size);
      height: var(--i-size);

      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  @include tzar-breakpoint-down(xl) {
    &__item {
      --i-size: 37px;

      height: 160px;
      padding-block: 20px;
      font-size: 14px;
    }
  }
  @include tzar-breakpoint-down(md) {
    &__item {
      padding-block: 30px;
      padding-inline: 8%;

      &:first-child {
        padding-inline-start: 28px;

        &::after {
          top: 12px;
          bottom: 12px;
          width: 10px;
        }
      }

      &:last-child {
        padding-inline-end: 28px;

        &::after {
          top: 12px;
          bottom: 12px;
          width: 10px;
        }
      }
    }
  }
  @include tzar-breakpoint-down(sm) {
    &__item {
      min-height: 135px;
      padding-block: 24px;
      padding-inline: 12px;

      &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 3;
        padding-inline-start: 20px;
      }

      &:nth-child(2) {
        grid-area: 1 / 3 / 2 / 5;
        padding-inline-end: 20px;
      }

      &:nth-child(3) {
        grid-area: 2 / 1 / 3 / 3;
        padding-inline-start: 20px;
      }

      &:nth-child(4) {
        grid-area: 2 / 3 / 3 / 5;
        padding-inline-end: 20px;
      }
    }
  }
}

.tools__title span:nth-child(2) {
  translate: 2.604vw 0;
}

@include tzar-breakpoint-down(sm) {
  .tools__title span:nth-child(3) {
    translate: 2.604vw 0;
  }
}
