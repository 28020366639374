/* stylelint-disable no-descending-specificity */

$accordion--button-bg: transparent;
$accordion--button-bg-hover: transparent;
$accordion--button-bg-active: transparent;
$accordion--button-text-color: $color-gray-3;
$accordion--button-text-color-hover: $color-orange;
$accordion--button-text-color-active: $color-orange;
$accordion--button-font-size: 20px;
$accordion--article-bg: transparent;
$accordion--article-text-color: black;
$accordion--icon-size: 10px;
$accordion--icon-color: $accordion--button-text-color;
$accordion--icon-color-hover: $accordion--button-text-color-hover;
$accordion--icon-color-active: $accordion--button-text-color-active;
$breakpoint-tabs: 889px;
$animationTime: 300ms;

.accordion {
  @mixin button {
    color: $accordion--button-text-color;
    text-align: start;
    background-color: $accordion--button-bg;
    border-bottom: 3px solid currentcolor;
  }
  @mixin tabs {
    .accordion {
      &__button,
      &__button-close {
        display: none;
      }

      &__sections {
        gap: 0;
      }

      &__section {
        margin: 0;
      }

      &__article {
        overflow: unset;
        display: none;
        height: auto;
        transition: none;
      }
    }
  }
  @mixin accs {
    .accordion {
      &__button {
        display: flex;
        color: black;
        border-bottom: 1px solid #b6b6b6;
      }

      &__button-close {
        display: block;
      }

      &__tabs {
        display: none;
      }

      &__sections {
        gap: 1px;
      }

      &__article {
        overflow: clip;
        display: block;
        height: 0;
        transition: height $animationTime ease-in-out;
      }
    }
  }

  // stylelint-disable-next-line scss/at-mixin-pattern
  @mixin buttonExpand {
    color: $accordion--button-text-color-active;
    background-color: $accordion--button-bg-active;
  }

  &:has(.accordion__tabs) {
    &.acc--no-tabs {
      @include accs;
    }

    .is-expand .accordion__button {
      color: $color-orange;
    }
    @media screen and (min-width: ($breakpoint-tabs + 1px)) {
      @include tabs;
    }
    @media screen and (max-width: $breakpoint-tabs) {
      @include accs;
    }
  }

  &:not(:has(.accordion__tabs)) {
    @include accs;
  }

  &__sections {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }

  &__button {
    cursor: pointer;

    position: relative;

    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding-block: 10px;
    padding-inline: 20px 40px;

    text-align: start;

    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
    transition-property: color, background-color;
    @include button;
  }

  &__icon {
    position: relative;
    width: 18px;
    height: 18px;
    transition: all 0.2s ease-in-out;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: #b6b6b6;
      transition: all 0.2s ease-in-out;
    }

    &::before {
      top: 50%;
      left: 0;
      translate: 0 -50%;

      width: 100%;
      height: 1.5px;
    }

    &::after {
      top: 0;
      left: 50%;
      translate: -50% 0;

      width: 1.5px;
      height: 100%;
    }
  }

  &__title-wrap {
    display: flex;
    gap: 15px;
    align-items: center;
  }

  &__title-img {
    width: 80px;
    height: 80px;
    margin: 0;

    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }

  &__title {
    flex-grow: 1;
    margin: 0;
    font-size: $accordion--button-font-size;
  }

  &__article {
    position: relative;

    margin: 0;
    padding: 0;

    color: $accordion--article-text-color;

    background-color: $accordion--article-bg;
  }

  &__inner {
    padding-block: 30px;
    padding-inline: 10%;
  }

  &__tabs {
    display: flex;
    justify-content: space-between;
  }

  &__tab {
    display: block;
    flex-grow: 1;
  }

  &__tab-button {
    width: 100%;
    margin: 0;
    padding-block: 16px;
    padding-inline: 10px;
    @include button;
  }

  &__tabs-text {
    font-size: $accordion--button-font-size;
  }

  &__section:not(.is-expand) .accordion__button,
  &__tab:not(.is-expand) .accordion__tab-button {
    @include hover {
      color: $accordion--button-text-color-hover;
      background-color: $accordion--button-bg-hover;

      .accordion__icon {
        color: $accordion--button-text-color-hover;
      }
    }
  }

  .is-expand .accordion {
    &__button,
    &__tab-button {
      @include buttonExpand;
    }

    &__icon {
      transform: rotate(45deg);

      &::before,
      &::after {
        background-color: $color-orange;
      }
    }
  }
  @include tzar-breakpoint-down(xl) {
    &__inner {
      padding-inline: 5%;
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__inner {
      padding-inline: 0;
    }
  }
  @include tzar-breakpoint-down(md) {
    &__inner {
      padding-block: 20px;
    }
  }
}

.choice {
  &__title {
    grid-area: 1 / 2 / 2 / -1;
    margin-block-end: 60px;
  }

  &__accordion {
    grid-area: 2 / 2 / 3 / -1;
  }

  &__pic {
    display: flex;
    justify-content: center;
    height: 450px;
    margin-block-end: 50px;

    img {
      height: 100%;
      object-fit: contain;
    }
  }

  &__text {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
      width: 211px;
    }
  }

  &__status {
    width: fit-content;
    padding: 8px;

    font-size: 20px;
    line-height: 1.4;
    color: $color-text;

    background-color: $color-gray-1;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 15px;

    p {
      margin-block-end: 0;
    }
  }

  &__place,
  &__time-text,
  &__time-year,
  &__stat-text {
    font-size: 22px;
  }

  &__project {
    font-size: clamp(24px, 1.2vw, 34px);
    font-weight: 600;
  }

  &__place {
    font-size: 22px;
  }

  &__time {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__stat {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__stat-text {
    margin-inline-end: 40px;
  }

  &__stat-num {
    flex-shrink: 0;
    font-size: clamp(24px, 1.2vw, 32px);
    font-weight: 600;
    color: $color-orange;
  }
  @include tzar-breakpoint-down(xl) {
    &__pic {
      height: 350px;
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__title {
      grid-area: 1 / 1 / 2 / -1;
      margin-block-end: 45px;
    }

    &__accordion {
      grid-area: 2 / 1 / 3 / -1;
    }
  }
  @include tzar-breakpoint-down(md) {
    &__title {
      margin-block-end: 30px;
    }

    &__pic {
      height: 250px;
    }

    &__text {
      flex-direction: column;
      gap: 30px;
    }

    &__logo {
      flex-direction: row;
      gap: 30px;
      align-items: center;
    }

    &__place,
    &__time-text,
    &__time-year,
    &__stat-text {
      font-size: 18px;
    }

    &__stat-text {
      margin-inline-end: 20px;
    }
  }
  @include tzar-breakpoint-down(sm) {
    &__logo img {
      width: 140px;
    }
  }
  @include tzar-breakpoint-down(xs) {
    &__logo img {
      width: 130px;
    }

    &__status {
      font-size: 12px;
    }

    &__pic {
      height: 220px;
      margin-block-end: 30px;
    }
  }
}
