.header {
  translate: 0 -100px;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55);

  &.anim {
    translate: 0;
  }
}

.front__pic {
  img {
    scale: 1.2;
    transition: all 1.1s ease-in-out;
  }

  &.anim img {
    scale: 1;
  }
}

.title {
  & > span {
    overflow: hidden;

    span {
      translate: 0 100%;
      opacity: 0;
      transition: all 1.1s ease-in-out;
    }
  }

  &.anim > span span {
    translate: 0;
    opacity: 1;
  }
}

.section__title {
  translate: -80% 0;
  opacity: 0;
  transition: all 1.1s ease-in-out;

  &.anim {
    translate: 0;
    opacity: 1;
  }
}

.assets {
  &__item {
    @include animLeft;
    @include tzar-breakpoint-down(sm) {
      &:nth-child(even) {
        @include animRight;
      }
    }
  }
}

.trust {
  &__item {
    @include animLeft;
    @include tzar-breakpoint-down(sm) {
      &:nth-child(even) {
        @include animRight;
      }
    }
  }
}

.pros {
  &__item {
    @include animLeft;

    &:nth-child(even) {
      @include animRight;
    }
    @include tzar-breakpoint-down(sm) {
      &:nth-child(even) {
        @include animLeft;
      }
    }
  }

  .title .pros__title--desk,
  .title .pros__title--mob {
    & > span {
      overflow: hidden;

      span {
        translate: 0 100%;
        opacity: 0;
        transition: all 1.1s ease-in-out;
      }
    }

    &.anim > span span {
      translate: 0;
      opacity: 1;
    }
  }
}

.pic {
  &__wrap {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__img {
    translate: 5% -5%;
    scale: 1.1;
    transition: all 1.1s 0.3s ease-in-out;

    &--small {
      translate: 15% -15%;
    }
  }

  // &__decor {
  //   translate: 30% -30%;
  //   opacity: 0;
  //   transition: all 1.1s 0.3s ease-in-out;
  // }

  &.anim .pic {
    &__img {
      translate: 0;
      scale: 1;
    }

    // &__decor {
    //   translate: 0;
    //   opacity: 1;
    // }
  }
}

.form {
  &__item {
    @for $i from 1 through 7 {
      &:nth-child(#{$i}):not(.anim-end) {
        transition-delay: #{($i - 1) * 0.1}s !important;
      }
    }
  }
}

// stylelint-disable-next-line selector-class-pattern
.animLeft {
  @include animLeft;
}

// stylelint-disable-next-line selector-class-pattern
.animRight {
  @include animRight;
}

// stylelint-disable-next-line selector-class-pattern
.animBottom {
  @include animBottom;
}
