.header {
  position: fixed;
  z-index: var(--header-z);
  top: 25px;
  left: var(--p-inline);

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: calc(100% - (var(--p-inline) * 2));
  height: 64px;
  padding-block: 10px;
  padding-inline: 30px;

  background-color: white;
  border-radius: 80px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);

  &__logos {
    position: relative;

    display: flex;
    gap: 32px;
    align-items: center;

    height: 100%;

    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: 50%;
      translate: -50% 0;

      width: 1.5px;
      height: 100%;

      background-color: $color-black-1;
    }
  }

  &__balmax,
  &__evraz {
    display: flex;
    align-items: center;
    width: 127px;
    height: 100%;
  }

  &__list {
    display: flex;
    gap: 70px;
  }

  &__link {
    position: relative;

    &::after {
      content: '';

      position: absolute;
      right: 0;
      bottom: 0;

      width: 0;
      height: 1.5px;

      background-color: currentcolor;

      transition: all 0.2s ease;
    }
    @include hover {
      &::after {
        left: 0;
        width: 100%;
      }
    }
  }

  img {
    width: 100%;
    object-fit: cover;
  }

  &__burger {
    flex-direction: column;
    gap: 2px;
    justify-content: space-between;

    width: 32px;
    height: 15px;
    padding-inline: 6px;
  }

  &__burger-line {
    display: block;

    width: 20px;
    height: 2px;

    background-color: $color-text;

    transition: all 0.2s ease-in-out;
  }
  @include tzar-breakpoint-up(sm) {
    &__burger {
      display: none;
    }
  }
  @include tzar-breakpoint-down(xxl) {
    &__list {
      gap: 60px;
    }
  }
  @include tzar-breakpoint-down(xl) {
    height: 54px;

    &__list {
      gap: 50px;
    }
  }
  @include tzar-breakpoint-down(lg) {
    height: 50px;
    padding-block: 5px;
    padding-inline: 24px;

    &__list {
      gap: 26px;
    }

    &__logos {
      gap: 26px;
    }

    &__balmax,
    &__evraz {
      width: 106px;
    }
  }
  @include tzar-breakpoint-down(md) {
    padding-inline: 12px;

    &__list {
      gap: 18px;
    }

    &__link {
      font-size: 16px;
    }
  }
  @include tzar-breakpoint-down(md) {
    top: 15px;
    height: 40px;

    &__logos {
      gap: 20px;
    }

    &__balmax,
    &__evraz {
      width: 96px;
    }

    &__burger {
      display: flex;
    }

    &__nav {
      position: absolute;
      top: 60px;
      right: 0;
      translate: calc(100% + var(--p-inline));

      width: auto;

      background-color: #fff;
      border-radius: 20px;
      box-shadow: 0 2px 5px rgb(0 0 0 / 10%);

      transition: 0.2s ease-in-out;
    }

    &__list {
      flex-direction: column;
      gap: 12px;
      justify-content: center;

      padding: 10px 20px;

      text-align: center;
    }

    .menu-open & {
      &__nav {
        translate: 0;
      }

      &__burger-line {
        &:nth-child(1) {
          translate: 0 6px;
          rotate: 45deg;
        }

        &:nth-child(2) {
          transform: scaleX(0);
        }

        &:nth-child(3) {
          translate: 0 -5px;
          rotate: -45deg;
        }
      }
    }
  }
}
